import { FunctionComponent, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { useStoreActions, useStoreState } from "../../store/_store";
import { Alert, Button, Col, FormGroup, Label, Row } from "reactstrap";
import { PageHeader } from "jack-hermanson-component-lib";
import { FaCheck, FaExclamationTriangle, FaTimes } from "react-icons/all";
import { BUTTON_ICON_CLASSES, SUBMIT_BUTTON_COLOR } from "../../constants";
import { AuthTabs } from "./AuthTabs";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "../../utils/LocalStorage";
import { PasswordInput } from "../Utils/PasswordInput";

export const PasswordPage: FunctionComponent = () => {
    useAuth();

    const history = useHistory();

    const password = useStoreState(state => state.password);
    const setPassword = useStoreActions(actions => actions.setPassword);

    const [formPassword, setFormPassword] = useState("");

    return (
        <div>
            <AuthTabs />
            {renderPageHeader()}
            {renderPasswordStatus()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Encryption Password"}>
                        {password && (
                            <Button
                                size="sm"
                                onClick={() => {
                                    setPassword(undefined);
                                }}
                            >
                                <FaTimes className={BUTTON_ICON_CLASSES} />{" "}
                                Unset Password
                            </Button>
                        )}
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderPasswordStatus() {
        return (
            <Row>
                <Col>
                    {password ? (
                        <Alert fade={false} color="success">
                            <FaCheck className="me-2" />
                            Password is set.
                        </Alert>
                    ) : (
                        <Alert fade={false} color="warning">
                            <FaExclamationTriangle className="me-2" />
                            Password is not set.
                        </Alert>
                    )}
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <div>
                <Row>
                    <Col>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                setPassword(formPassword);
                                const redirectPath: string | undefined =
                                    LocalStorage.getRedirectPath();
                                if (redirectPath) {
                                    LocalStorage.removeRedirectPath();
                                    history.push(redirectPath);
                                } else {
                                    setFormPassword("");
                                    document
                                        .getElementById("password-input")
                                        ?.blur();
                                }
                            }}
                        >
                            {renderPasswordInput()}
                            <div className="bottom-buttons">
                                <Button
                                    color={SUBMIT_BUTTON_COLOR}
                                    type="submit"
                                >
                                    Set Password
                                </Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }

    function renderPasswordInput() {
        const id = "password-input";
        return (
            <FormGroup>
                <Label for={id} className="required">
                    Encryption Password
                </Label>
                <PasswordInput
                    autoFocus={password === undefined}
                    id={id}
                    value={formPassword}
                    onChange={e => setFormPassword(e.target.value)}
                />
            </FormGroup>
        );
    }
};
