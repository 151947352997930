import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { NoteRecord } from "@secure-note/shared";
import { ConfirmationModal } from "jack-hermanson-component-lib";
import { NoteClient } from "../../clients/NoteClient";
import { useStoreActions, useStoreState } from "../../store/_store";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";

interface Props {
    note: NoteRecord;
    callback: () => any;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ConfirmNoteDeletion: FunctionComponent<Props> = ({
    note,
    callback,
    isOpen,
    setIsOpen,
}: Props) => {
    const currentUser = useStoreState(state => state.currentUser);
    const addAlert = useStoreActions(actions => actions.addAlert);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Confirm Note Deletion"
            onConfirm={async () => {
                if (currentUser?.token) {
                    try {
                        const deleted = await NoteClient.delete(
                            note.id,
                            currentUser.token
                        );
                        if (deleted) {
                            addAlert(
                                successAlert(
                                    `note with ID ${note.id}`,
                                    "deleted"
                                )
                            );
                            setIsOpen(false);
                            callback();
                        }
                    } catch (error: any) {
                        setIsOpen(false);
                        console.error(error);
                        addAlert(errorAlert(error.message));
                    }
                }
            }}
            buttonText="Delete"
            buttonColor="danger"
        >
            Are you sure you want to delete the note titled "{note.title}"?
        </ConfirmationModal>
    );
};
