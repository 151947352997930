import CryptoJS from "crypto-js";
import { BookmarkRecord, NoteRecord } from "@secure-note/shared";

export abstract class Encryption {
    static encrypt(message: string, password: string): string {
        return CryptoJS.AES.encrypt(message, password).toString();
    }

    static decrypt(encryptedText: string, password: string) {
        const bytes = CryptoJS.AES.decrypt(encryptedText, password);
        if (bytes.sigBytes < 0) {
            throw new Error("Decryption Error");
        }
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    /**
     * Decrypt one note
     * @param note
     * @param password
     */
    static decryptNote(note: NoteRecord, password: string): NoteRecord {
        try {
            note.title = Encryption.decrypt(note.title, password);
            note.body = Encryption.decrypt(note.body, password);
            return note;
        } catch (error) {
            throw new Error(`Could not decrypt note with ID ${note.id}`);
        }
    }

    /**
     * Decrypt multiple notes
     * @param notes
     * @param password
     */
    static decryptNotes(notes: NoteRecord[], password: string): NoteRecord[] {
        const outputNotes: NoteRecord[] = [];

        for (let note of notes) {
            try {
                const decryptedNote = this.decryptNote(note, password);
                outputNotes.push(decryptedNote);
            } catch (error) {
                console.error(error);
            }
        }

        return outputNotes;
    }

    /**
     * Decrypt one bookmark
     * @param bookmark
     * @param password
     */
    static decryptBookmark(
        bookmark: BookmarkRecord,
        password: string
    ): BookmarkRecord {
        try {
            bookmark.title = this.decrypt(bookmark.title, password);
            bookmark.comment = this.decrypt(bookmark.comment, password);
            bookmark.url = this.decrypt(bookmark.url, password);
            return bookmark;
        } catch (error) {
            throw new Error(
                `Could not decrypt bookmark with ID ${bookmark.id}`
            );
        }
    }

    /**
     * Decrypt multiple bookmarks
     * @param bookmarks
     * @param password
     */
    static decryptBookmarks(
        bookmarks: BookmarkRecord[],
        password: string
    ): BookmarkRecord[] {
        const outputBookmarks: BookmarkRecord[] = [];

        for (let bookmark of bookmarks) {
            try {
                const decryptedBookmark = this.decryptBookmark(
                    bookmark,
                    password
                );
                outputBookmarks.push(decryptedBookmark);
            } catch (error) {
                console.error(error);
            }
        }

        return outputBookmarks;
    }
}
