import { useStoreState } from "../store/_store";
import { useEffect, useState } from "react";
import { SongRecord } from "@secure-note/shared";
import { SongClient } from "../clients/SongClient";

export const useSong = (id: number) => {
    const currentUser = useStoreState(state => state.currentUser);

    const [song, setSong] = useState<SongRecord | undefined>(undefined);

    useEffect(() => {
        if (currentUser?.token) {
            SongClient.getOne(id, currentUser.token).then(data => {
                setSong(data);
            });
        }
    }, [currentUser?.token, setSong, id]);

    return { song };
};
