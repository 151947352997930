import { FunctionComponent } from "react";
import { makeFractionOf100 } from "../../utils/makeFractionOf100";
import { Progress } from "reactstrap";

interface Props {
    count: number;
    total?: number;
}

export const ProgressBar: FunctionComponent<Props> = ({ count, total }) => {
    return (
        <Progress
            style={{ borderRadius: "4px" }}
            animated
            bar
            striped
            value={makeFractionOf100({
                count: count,
                total: total ?? 0,
            })}
        >
            {count} of {total}
        </Progress>
    );
};
