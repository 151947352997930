import axios from "axios";
import { TagRecord, TagRequest } from "@secure-note/shared";
import { getAuthHeader } from "jack-hermanson-ts-utils";

export abstract class TagClient {
    static baseUrl = "/api/tags";

    static async getAll(token: string) {
        const response = await axios.get<TagRecord[]>(
            this.baseUrl,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async create(tagRequest: TagRequest, token: string) {
        const response = await axios.post<TagRecord>(
            this.baseUrl,
            tagRequest,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async edit(id: number, tagRequest: TagRequest, token: string) {
        const response = await axios.put<TagRecord>(
            `${this.baseUrl}/${id}`,
            tagRequest,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async delete(id: number, token: string) {
        const response = await axios.delete<boolean>(
            `${this.baseUrl}/${id}`,
            getAuthHeader(token)
        );
        return response.data;
    }
}
