import { Fragment, FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../store/_store";
import { errorAlert } from "jack-hermanson-ts-utils";

export const LandingPage: FunctionComponent = () => {
    const history = useHistory();
    const currentUser = useStoreState(state => state.currentUser);
    const addAlert = useStoreActions(actions => actions.addAlert);

    useEffect(() => {
        if (currentUser && currentUser.verified) {
            history.replace("/notes");
            return;
        } else if (currentUser && !currentUser.verified) {
            addAlert(errorAlert("Your account is not verified."));
        }
        history.replace("/auth");
        return;
    }, [history, currentUser, addAlert]);

    return <Fragment />;
};
