import { SongRecord, SongRequest } from "@secure-note/shared";
import { getAuthHeader } from "jack-hermanson-ts-utils";
import axios from "axios";

export abstract class SongClient {
    static baseUrl = "/api/songs";

    static async getAll(token: string) {
        const response = await axios.get<SongRecord[]>(
            this.baseUrl,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async getOne(id: number, token: string) {
        const response = await axios.get<SongRecord>(
            `${this.baseUrl}/${id}`,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async create(songRequest: SongRequest, token: string) {
        songRequest = { ...songRequest, notes: songRequest.notes || undefined };
        const response = await axios.post<SongRecord>(
            this.baseUrl,
            songRequest,
            getAuthHeader(token)
        );
        return response.data;
    }
}
