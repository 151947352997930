import { useEffect } from "react";
import { LocalStorage } from "./LocalStorage";
import { useHistory } from "react-router-dom";
import { useStoreState } from "../store/_store";

export const useAuth = () => {
    const history = useHistory();
    const currentUser = useStoreState(state => state.currentUser);

    useEffect(() => {
        if (window.location.pathname !== "/auth") {
            if (window.location.pathname !== "/auth/password") {
                LocalStorage.saveRedirectPath(window.location.pathname);
                console.log(
                    `Saving to redirect path "${window.location.pathname}"`
                );
            }
        } else {
            console.log(`Location is ${window.location.pathname}`);
            LocalStorage.removeRedirectPath();
            console.log("Removing redirect path");
        }

        if (!currentUser) {
            history.replace("/auth/login");
        } else if (currentUser && !currentUser.verified) {
            history.replace("/forbidden");
        }
    }, [currentUser, history]);
};
