import { FunctionComponent } from "react";
import { SongRecord, SongRequest } from "@secure-note/shared";
import { Field, Form, Formik, FormikErrors, FormikProps } from "formik";
import * as yup from "yup";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { FormError } from "jack-hermanson-component-lib";
import { RESET_BUTTON_COLOR, SUBMIT_BUTTON_COLOR } from "../../constants";

interface Props {
    onSubmit: (songRequest: SongRequest) => Promise<any>;
    existingSong?: SongRecord;
}

const validationSchema = yup.object().shape({
    name: yup.string().label("Name").required(),
    artist: yup.string().label("Artist").required(),
    notes: yup.string().label("Notes").optional(),
});

interface FormValues {
    name: string;
    artist: string;
    notes: string;
}

export const CreateEditSongForm: FunctionComponent<Props> = ({
    onSubmit,
    existingSong,
}: Props) => {
    return (
        <Formik
            initialValues={{
                name: existingSong?.name || "",
                artist: existingSong?.artist || "",
                notes: existingSong?.notes || "",
            }}
            onSubmit={async data => {
                await onSubmit({
                    name: data.name,
                    artist: data.artist,
                    notes: data.notes,
                });
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onReset={() => {
                if (!existingSong) {
                    document.getElementById("name-input")?.focus();
                }
            }}
        >
            {({ errors }: FormikProps<FormValues>) => (
                <Form>
                    {renderName(errors)}
                    {renderArtist(errors)}
                    {renderNotes(errors)}
                    {renderButtons()}
                </Form>
            )}
        </Formik>
    );

    function renderName(errors: FormikErrors<FormValues>) {
        const id = "name-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Name
                </Label>
                <Field
                    name="name"
                    id={id}
                    type="text"
                    as={Input}
                    autoFocus={true}
                />
                <FormError>{errors.name}</FormError>
            </FormGroup>
        );
    }

    function renderArtist(errors: FormikErrors<FormValues>) {
        const id = "artist-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Artist
                </Label>
                <Field name="artist" id={id} type="text" as={Input} />
                <FormError>{errors.artist}</FormError>
            </FormGroup>
        );
    }

    function renderNotes(errors: FormikErrors<FormValues>) {
        const id = "notes-input";
        return (
            <FormGroup>
                <Label className="form-label" for={id}>
                    Notes
                </Label>
                <Field name="notes" id={id} type="textarea" as={Input} />
                <FormError>{errors.notes}</FormError>
            </FormGroup>
        );
    }

    function renderButtons() {
        return (
            <div className="bottom-buttons">
                <Button type="submit" color={SUBMIT_BUTTON_COLOR}>
                    Save
                </Button>
                <Button type="reset" color={RESET_BUTTON_COLOR}>
                    Reset
                </Button>
            </div>
        );
    }
};
