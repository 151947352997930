import { FunctionComponent } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { getRandomInt } from "../../utils/random";

export const LoadingBookmark: FunctionComponent = () => {
    const tags = [...Array(getRandomInt(1, 3)).keys()];

    return (
        <Card className="mb-3 no-mb-last">
            <CardHeader>
                <h5 className="card-title placeholder-glow d-flex mt-2">
                    <span
                        className={`placeholder col-${getRandomInt(
                            5,
                            8
                        )} col-lg-${getRandomInt(4, 6)}`}
                    />
                    <span className="placeholder col-2 col-lg-1 ms-auto" />
                </h5>
            </CardHeader>
            <CardBody className="placeholder-glow pt-1">
                <div>
                    <small className="placeholder placeholder-xs col-4 col-lg-3" />
                </div>
                <div>
                    <span className="placeholder placeholder-xs col-1" />
                </div>
                <div className="mb-2">
                    <span
                        className={`placeholder placeholder-lg col-${getRandomInt(
                            6,
                            8
                        )} col-lg-${getRandomInt(4, 6)}`}
                    />
                </div>
                <div>
                    <span className="placeholder placeholder-xs col-1" />
                </div>
                <div>
                    <span
                        className={`placeholder placeholder-lg col-${getRandomInt(
                            4,
                            11
                        )} col-lg-${getRandomInt(6, 8)}`}
                    />
                </div>
            </CardBody>
            <CardFooter className="placeholder-glow">
                {tags.map(t => (
                    <span
                        key={t}
                        className={`placeholder me-1 col-${getRandomInt(1, 4)}`}
                    />
                ))}
            </CardFooter>
        </Card>
    );
};
