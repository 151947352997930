import { Fragment, FunctionComponent, useState } from "react";
import { TagRecord } from "@secure-note/shared";
import { Field, FormikErrors } from "formik";
import { Badge, FormGroup, Input, Label } from "reactstrap";
import { FormError, LoadingSpinner } from "jack-hermanson-component-lib";
import { CreateTagModal } from "./CreateTagModal";

type SetFieldValue = (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
) => void;

interface FormValues {
    tagIds: string[];
}

interface Props {
    tags: TagRecord[];
    setFieldValue: SetFieldValue;
    errors: FormikErrors<FormValues>;
    values: FormValues;
}

export const TagsField: FunctionComponent<Props> = ({
    tags,
    setFieldValue,
    errors,
    values,
}: Props) => {
    const [showTagModal, setShowTagModal] = useState(false);

    return (
        <Fragment>
            {renderTags(errors)}
            {renderTagModal(setFieldValue, values)}
        </Fragment>
    );

    function renderTags(errors: FormikErrors<FormValues>) {
        return (
            <FormGroup>
                {tags ? (
                    <Fragment>
                        <Label className="form-label">
                            Tags{" "}
                            <Badge
                                color="primary"
                                className="small ms-1"
                                onClick={e => {
                                    e.preventDefault();
                                    setShowTagModal(true);
                                }}
                            >
                                New Tag
                            </Badge>
                        </Label>
                        {tags.map(tag => (
                            <FormGroup check key={tag.id}>
                                <Field
                                    as={Input}
                                    name="tagIds"
                                    type="checkbox"
                                    id={`tag-${tag.id}`}
                                    value={tag.id.toString()}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`tag-${tag.id}`}
                                >
                                    {tag.name}
                                </label>
                            </FormGroup>
                        ))}
                    </Fragment>
                ) : (
                    <LoadingSpinner />
                )}
                <FormError>{errors.tagIds}</FormError>
            </FormGroup>
        );
    }

    function renderTagModal(setFieldValue: SetFieldValue, values: FormValues) {
        return (
            <CreateTagModal
                isOpen={showTagModal}
                setIsOpen={setShowTagModal}
                afterSubmit={newTagId => {
                    setFieldValue("tagIds", [
                        ...values.tagIds,
                        newTagId.toString(),
                    ]);
                }}
            />
        );
    }
};
