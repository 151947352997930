import {
    Fragment,
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useAuth } from "../../utils/useAuth";
import { Button, CardBody, Col, Row } from "reactstrap";
import {
    LoadingSpinner,
    MobileToggleCard,
    PageHeader,
} from "jack-hermanson-component-lib";
import { usePassword } from "../../utils/usePassword";
import { useStoreState } from "../../store/_store";
import { BookmarkGlance } from "./BookmarkGlance";
import { useHistory } from "react-router-dom";
import { BUTTON_ICON_CLASSES, NEW_BUTTON_COLOR } from "../../constants";
import { FaPlus } from "react-icons/all";
import { BookmarkRecord } from "@secure-note/shared";
import { BookmarkClient } from "../../clients/BookmarkClient";
import { Encryption } from "../../utils/Encryption";
import { FilterCheckboxes } from "../Utils/FilterCheckboxes";
import { LoadingBookmark } from "./LoadingBookmark";
import { getRandomInt } from "../../utils/random";

export const BookmarksIndex: FunctionComponent = () => {
    useAuth();
    usePassword();

    const password = useStoreState(state => state.password);
    const currentUser = useStoreState(state => state.currentUser);
    const tags = useStoreState(state => state.tags)?.filter(
        t => t.forBookmarks
    );

    const history = useHistory();

    const [bookmarks, setBookmarks] = useState<BookmarkRecord[] | undefined>(
        undefined
    );
    const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    const fetchBookmarks = useCallback(() => {
        if (currentUser?.token && password) {
            setLoading(true);
            BookmarkClient.getAll(currentUser, selectedTagIds).then(data => {
                setBookmarks(Encryption.decryptBookmarks(data, password));
                setLoading(false);
            });
        }
    }, [selectedTagIds, setLoading, setBookmarks, currentUser, password]);

    useEffect(() => {
        fetchBookmarks();
    }, [fetchBookmarks]);

    return (
        <div>
            {renderPageHeader()}
            <Row>
                {renderFiltering()}
                {renderBookmarksList()}
            </Row>
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Bookmarks">
                        <Button
                            size="sm"
                            color={NEW_BUTTON_COLOR}
                            onClick={() => {
                                history.push("/bookmarks/new");
                            }}
                        >
                            <FaPlus className={BUTTON_ICON_CLASSES} />
                            New Bookmark
                        </Button>
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderFiltering() {
        return (
            <Col xs={12} lg={3}>
                <MobileToggleCard
                    cardTitle={"Filtering"}
                    className="mb-3 mb-lg-0 sticky-top"
                >
                    <CardBody>
                        {tags ? (
                            <FilterCheckboxes
                                label={"Tags"}
                                options={tags.map(tag => ({
                                    label: tag.name,
                                    value: tag.id,
                                }))}
                                selectedItems={selectedTagIds}
                                setSelectedItems={setSelectedTagIds}
                            />
                        ) : (
                            <LoadingSpinner />
                        )}
                        <div className="d-grid col-12 bottom-buttons">
                            <Button
                                size="sm"
                                color="secondary"
                                onClick={() => {
                                    setSelectedTagIds([]);
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </CardBody>
                </MobileToggleCard>
            </Col>
        );
    }

    function renderBookmarksList() {
        return (
            <Col>
                {bookmarks && currentUser && !loading ? (
                    <Fragment>
                        {bookmarks.map(bookmark => (
                            <BookmarkGlance
                                bookmark={bookmark}
                                className="mb-3 no-mb-last"
                                key={bookmark.id}
                                selectedTagIds={selectedTagIds}
                                setSelectedTagIds={setSelectedTagIds}
                                onDelete={() => {
                                    fetchBookmarks();
                                }}
                            />
                        ))}
                    </Fragment>
                ) : (
                    renderLoadingBookmarks()
                )}
            </Col>
        );
    }

    function renderLoadingBookmarks() {
        const numBookmarks = [...Array(getRandomInt(5, 10)).keys()];
        return (
            <Fragment>
                {numBookmarks.map(n => (
                    <LoadingBookmark key={n} />
                ))}
            </Fragment>
        );
    }
};
