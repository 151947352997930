import React, { FunctionComponent, useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/_store";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CreateEditTagForm } from "./CreateEditTagForm";
import { TagRequest } from "@secure-note/shared";
import { Encryption } from "../../utils/Encryption";
import { TagClient } from "../../clients/TagClient";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    afterSubmit: (newTagId: number) => any;
}

export const CreateTagModal: FunctionComponent<Props> = ({
    isOpen,
    setIsOpen,
    afterSubmit,
}: Props) => {
    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);
    const fetchTags = useStoreActions(actions => actions.fetchTags);
    const addAlert = useStoreActions(actions => actions.addAlert);

    const toggle = useCallback(() => {
        setIsOpen(o => !o);
    }, [setIsOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} autoFocus={false}>
            <ModalHeader toggle={toggle}>Create Tag</ModalHeader>
            <ModalBody>
                <CreateEditTagForm onSubmit={onSubmit} />
            </ModalBody>
        </Modal>
    );

    async function onSubmit(tagRequest: TagRequest) {
        if (currentUser?.token && password) {
            try {
                tagRequest.name = Encryption.encrypt(tagRequest.name, password);
                const newTag = await TagClient.create(
                    tagRequest,
                    currentUser.token
                );
                await fetchTags(currentUser.token);
                afterSubmit(newTag.id);
                toggle();
            } catch (error: any) {
                addAlert(error.message);
                toggle();
            }
        }
    }
};
