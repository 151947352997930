import { FunctionComponent, useState } from "react";
import { Input, InputProps } from "reactstrap";
import { EyeToggle } from "./EyeToggle";

interface Props extends InputProps {}

export const PasswordInput: FunctionComponent<Props> = props => {
    const [inputType, setInputType] = useState<"password" | "text">("password");

    return (
        <div className="d-flex">
            <Input {...props} type={inputType} />
            <EyeToggle
                inputType={inputType}
                setInputType={setInputType}
                elementId={props.id}
            />
        </div>
    );
};
