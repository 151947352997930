import { FunctionComponent } from "react";
import { ErrorPage } from "./ErrorPage";

export const Forbidden: FunctionComponent = () => {
    return (
        <ErrorPage
            title={"Forbidden"}
            message={"You cannot access this page."}
        />
    );
};
