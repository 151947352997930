import {
    Fragment,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { Button, CardBody, Col, Row } from "reactstrap";
import {
    LoadingSpinner,
    MobileToggleCard,
    PageHeader,
} from "jack-hermanson-component-lib";
import { useAuth } from "../../utils/useAuth";
import { useStoreState } from "../../store/_store";
import { useHistory } from "react-router-dom";
import {
    BUTTON_ICON_CLASSES,
    DEFAULT_SKIP_TAKE,
    NEW_BUTTON_COLOR,
    RESET_BUTTON_COLOR,
} from "../../constants";
import { FaPlus } from "react-icons/all";
import { NoteGlance } from "./NoteGlance";
import { usePassword } from "../../utils/usePassword";
import { FilterCheckboxes } from "../Utils/FilterCheckboxes";
import { LoadingNote } from "./LoadingNote";
import { getRandomInt } from "../../utils/random";
import { NoteRecord } from "@secure-note/shared";
import { NoteClient } from "../../clients/NoteClient";
import { Encryption } from "../../utils/Encryption";

export const NotesIndex: FunctionComponent = () => {
    useAuth();
    usePassword();

    const history = useHistory();

    const tags = useStoreState(state => state.tags)?.filter(t => t.forNotes);
    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);

    const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
    const [notes, setNotes] = useState<NoteRecord[] | undefined>(undefined);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(DEFAULT_SKIP_TAKE);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const keepExisting = useRef(false);
    const getKeepExisting = useCallback(
        () => keepExisting.current,
        [keepExisting]
    );
    const setKeepExisting = useCallback(
        (val: boolean) => (keepExisting.current = val),
        [keepExisting]
    );

    const fetchNotes = useCallback(() => {
        if (currentUser?.token && password) {
            console.log("Fetching notes");
            setLoading(true);

            if (getKeepExisting()) {
                NoteClient.getAll({
                    account: currentUser,
                    tagIds: selectedTagIds,
                    skip: skip,
                    take: take,
                }).then(data => {
                    setNotes(prev => {
                        if (prev) {
                            return [
                                ...prev,
                                ...Encryption.decryptNotes(
                                    data.items,
                                    password
                                ),
                            ];
                        } else {
                            return data.items;
                        }
                    });
                    setTotal(data.total);
                    setCount(prev => prev + data.count);
                    setLoading(false);
                    setKeepExisting(false);
                });
            } else {
                console.log("start fresh");
                NoteClient.getAll({
                    account: currentUser,
                    tagIds: selectedTagIds,
                    skip: 0,
                    take: DEFAULT_SKIP_TAKE,
                }).then(data => {
                    setNotes(Encryption.decryptNotes(data.items, password));
                    setTotal(data.total);
                    setCount(data.count);
                    setLoading(false);
                });
            }
        }
    }, [
        currentUser,
        password,
        selectedTagIds,
        skip,
        take,
        getKeepExisting,
        setTotal,
        setCount,
        setLoading,
        setKeepExisting,
    ]);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes]);

    return (
        <div>
            {renderHeader()}
            <Row>
                {renderFiltering()}
                {renderNotesList()}
            </Row>
        </div>
    );

    function renderHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Notes">
                        <Button
                            size="sm"
                            color={NEW_BUTTON_COLOR}
                            onClick={() => {
                                history.push("/notes/new");
                            }}
                        >
                            <FaPlus className={BUTTON_ICON_CLASSES} /> New Note
                        </Button>
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderFiltering() {
        return (
            <Col xs={12} lg={3}>
                <MobileToggleCard
                    cardTitle={"Filtering"}
                    className="sticky-top mb-3 mb-lg-0"
                >
                    <CardBody>
                        {tags ? (
                            <FilterCheckboxes
                                label={"Tags"}
                                options={tags.map(t => ({
                                    value: t.id,
                                    label: t.name,
                                }))}
                                selectedItems={selectedTagIds}
                                setSelectedItems={setSelectedTagIds}
                            />
                        ) : (
                            <LoadingSpinner />
                        )}
                        <div className="bottom-buttons">
                            <div className="col-12 d-grid">
                                <Button
                                    size="sm"
                                    color={RESET_BUTTON_COLOR}
                                    onClick={() => {
                                        setSelectedTagIds([]);
                                    }}
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </MobileToggleCard>
            </Col>
        );
    }

    function renderNotesList() {
        return (
            <Col xs={12} lg={9}>
                {notes && currentUser && !loading ? (
                    <Fragment>
                        {notes.map(note => (
                            <NoteGlance
                                className="mb-3"
                                note={note}
                                key={note.id}
                                onDelete={async () => {
                                    setSkip(0);
                                    setTake(DEFAULT_SKIP_TAKE);
                                }}
                                selectedTagIds={selectedTagIds}
                                setSelectedTagIds={setSelectedTagIds}
                            />
                        ))}
                        {renderLoadMore()}
                    </Fragment>
                ) : (
                    renderLoadingNotes()
                )}
            </Col>
        );
    }

    function renderLoadingNotes() {
        const numBookmarks = [...Array(getRandomInt(5, 10)).keys()];
        return (
            <Fragment>
                {numBookmarks.map(n => (
                    <LoadingNote key={n} />
                ))}
            </Fragment>
        );
    }

    function renderLoadMore() {
        if (notes) {
            const remaining = total - count;
            const loadMoreAmount = Math.min(remaining, DEFAULT_SKIP_TAKE);
            return (
                <div>
                    <p>
                        Showing {count} of {total} notes.
                    </p>
                    {count < total && (
                        <div>
                            <Button
                                color="secondary"
                                className="me-2"
                                onClick={() => loadMore(loadMoreAmount)}
                            >
                                Load {loadMoreAmount} more
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => loadMore(remaining)}
                            >
                                Load all
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
    }

    function loadMore(amount: number) {
        keepExisting.current = true;
        setSkip(count);
        setTake(amount);
    }
};
