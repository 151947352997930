export const makeFractionOf100 = ({
    count,
    total,
}: {
    count: number;
    total: number;
}) => {
    if (total === 0) {
        // no divide by 0
        return 0;
    }

    return Math.ceil((count / total) * 100);
};
