import { TagRecord, TagRequest } from "@secure-note/shared";
import { Fragment, FunctionComponent } from "react";
import { Field, Form, Formik, FormikErrors } from "formik";
import * as yup from "yup";
import { FormError, LoadingSpinner } from "jack-hermanson-component-lib";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { RESET_BUTTON_COLOR, SUBMIT_BUTTON_COLOR } from "../../constants";

interface Props {
    onSubmit: (tagRequest: TagRequest) => any;
    existingTag?: TagRecord;
}

const validationSchema = yup.object().shape({
    name: yup.string().required().label("Name"),
    forBookmarks: yup.boolean().required().label("Bookmarks"),
    forNotes: yup.boolean().required().label("Notes"),
});

type Err = FormikErrors<TagRequest>;

export const CreateEditTagForm: FunctionComponent<Props> = ({
    existingTag,
    onSubmit,
}: Props) => {
    return (
        <Formik
            initialValues={{
                name: existingTag?.name || "",
                forBookmarks: existingTag ? existingTag.forBookmarks : true,
                forNotes: existingTag ? existingTag.forNotes : true,
            }}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmit({
                    name: data.name,
                    forBookmarks: data.forBookmarks,
                    forNotes: data.forNotes,
                });
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, isSubmitting }) => (
                <Form>
                    {isSubmitting ? (
                        <LoadingSpinner />
                    ) : (
                        <Fragment>
                            {renderName(errors)}
                            {renderAssociations()}
                            {renderButtons()}
                        </Fragment>
                    )}
                </Form>
            )}
        </Formik>
    );

    function renderName(errors: Err) {
        const id = "name-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Name
                </Label>
                <Field
                    id={id}
                    autoFocus={true}
                    autoCapitalize="off"
                    autoComplete="off"
                    name="name"
                    type="text"
                    as={Input}
                    placeholder="some tag..."
                />
                <FormError>{errors.name}</FormError>
            </FormGroup>
        );
    }

    function renderAssociations() {
        return (
            <FormGroup>
                <Label className="form-label">Associations</Label>
                {renderForNotes()}
                {renderForBookmarks()}
            </FormGroup>
        );
    }

    function renderForBookmarks() {
        const id = "for-bookmarks-input";
        return (
            <FormGroup check>
                <Field
                    as={Input}
                    id={id}
                    name="forBookmarks"
                    type="checkbox"
                    className="form-check-input"
                />
                <Label for={id} className="form-check-label">
                    Allowed on Bookmarks
                </Label>
            </FormGroup>
        );
    }

    function renderForNotes() {
        const id = "for-notes-input";
        return (
            <FormGroup check>
                <Field
                    as={Input}
                    id={id}
                    name="forNotes"
                    type="checkbox"
                    className="form-check-input"
                />
                <Label for={id} className="form-check-label">
                    Allowed on Notes
                </Label>
            </FormGroup>
        );
    }

    function renderButtons() {
        return (
            <div className="bottom-buttons">
                <Button type="submit" color={SUBMIT_BUTTON_COLOR}>
                    Save
                </Button>
                <Button type="reset" color={RESET_BUTTON_COLOR}>
                    Reset
                </Button>
            </div>
        );
    }
};
