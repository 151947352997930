import { Action, action, Thunk, thunk } from "easy-peasy";
import { StoreModel } from "./_store";
import { TagRecord } from "@secure-note/shared";
import { TagClient } from "../clients/TagClient";
import { Encryption } from "../utils/Encryption";

export interface TagStoreModel {
    tags: TagRecord[] | undefined;
    setTags: Action<StoreModel, TagRecord[] | undefined>;
    fetchTags: Thunk<StoreModel, string>;
}

export const tagStore: TagStoreModel = {
    tags: undefined,
    setTags: action((state, payload) => {
        if (state.password) {
            if (!payload) {
                state.tags = undefined;
            } else {
                const password = state.password;

                const tags: TagRecord[] = [];
                const failedTagIds: number[] = [];
                // decrypt here
                for (let tag of payload) {
                    try {
                        tag.name = Encryption.decrypt(tag.name, password);
                        tags.push(tag);
                    } catch (error) {
                        failedTagIds.push(tag.id);
                    }
                }

                if (failedTagIds.length) {
                    console.error(
                        `Failed to decrypt tags with IDs: ${failedTagIds.toString()}`
                    );
                }
                tags.sort((a, b) => (a.name < b.name ? -1 : 1));
                state.tags = tags;
            }
        }
    }),
    fetchTags: thunk(async (actions, token) => {
        try {
            const tags = await TagClient.getAll(token);
            actions.setTags(tags);
        } catch (error) {
            console.error(error);
        }
    }),
};
