import { FunctionComponent } from "react";
import { ErrorPage } from "./ErrorPage";

export const NotFound: FunctionComponent = () => {
    return (
        <ErrorPage
            title={"Not Found"}
            message={"That resource does not exist."}
        />
    );
};
