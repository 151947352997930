import { FunctionComponent } from "react";
import { SongRecord } from "@secure-note/shared";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Row,
} from "reactstrap";
import { ActionCardHeader } from "jack-hermanson-component-lib";
import {
    BUTTON_ICON_CLASSES,
    DATE_FORMAT,
    LONG_DATE_FORMAT,
} from "../../constants";
import { FaEdit } from "react-icons/all";
import moment from "moment";
import { Link } from "react-router-dom";

interface Props {
    song: SongRecord;
}

export const SongGlance: FunctionComponent<Props> = ({ song }: Props) => {
    return (
        <Card className="mb-3 no-mb-last">
            <CardHeader className="d-flex">
                <h5 className="me-auto my-auto">
                    <Link className="header-link" to={`/songs/${song.id}`}>
                        {song.name}
                    </Link>
                </h5>
                <Button className={BUTTON_ICON_CLASSES}>
                    <FaEdit /> Edit
                </Button>
            </CardHeader>
            <CardBody>
                <p
                    className={
                        (!song.notes ? "text-muted fst-italic" : "") + " mb-0"
                    }
                >
                    {song.notes || "No notes"}
                </p>
            </CardBody>
            <CardFooter className="d-flex">
                <small className="text-muted">
                    {moment(song.created).format(DATE_FORMAT)}
                </small>
            </CardFooter>
        </Card>
    );
};
