import { RouteComponentProps } from "react-router-dom";
import { FunctionComponent } from "react";
import { Col, Row } from "reactstrap";

interface Props extends RouteComponentProps<{ id: string }> {}

export const EditSong: FunctionComponent<Props> = ({ match }) => {
    const id = match.params.id;

    return <div>{renderPageHeader()}</div>;

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <h1>Edit {id}</h1>
                </Col>
            </Row>
        );
    }
};
