import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { useStoreActions, useStoreState } from "../../store/_store";
import { BookmarkRecord, BookmarkRequest } from "@secure-note/shared";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { Col, Row } from "reactstrap";
import { LoadingSpinner, PageHeader } from "jack-hermanson-component-lib";
import { CreateEditBookmarkForm } from "./CreateEditBookmarkForm";
import { Encryption } from "../../utils/Encryption";
import { BookmarkClient } from "../../clients/BookmarkClient";

interface Props extends RouteComponentProps<{ id: string }> {}

export const EditBookmark: FunctionComponent<Props> = ({ match }: Props) => {
    useAuth();
    usePassword();

    const history = useHistory();

    const addAlert = useStoreActions(actions => actions.addAlert);
    const password = useStoreState(state => state.password);
    const currentUser = useStoreState(state => state.currentUser);

    const [bookmark, setBookmark] = useState<BookmarkRecord | undefined>(
        undefined
    );

    useEffect(() => {
        if (currentUser?.token && password) {
            BookmarkClient.getOne(
                parseInt(match.params.id),
                currentUser.token
            ).then(data => {
                setBookmark(Encryption.decryptBookmark(data, password));
            });
        }
    }, [match.params.id, setBookmark, addAlert, currentUser, password]);

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Edit Bookmark"} />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    {bookmark ? (
                        <CreateEditBookmarkForm
                            onSubmit={onSubmit}
                            existingBookmark={bookmark}
                        />
                    ) : (
                        <LoadingSpinner />
                    )}
                </Col>
            </Row>
        );
    }

    async function onSubmit(bookmarkRequest: BookmarkRequest) {
        if (currentUser?.token && password) {
            try {
                bookmarkRequest.title = Encryption.encrypt(
                    bookmarkRequest.title,
                    password
                );
                bookmarkRequest.url = Encryption.encrypt(
                    bookmarkRequest.url,
                    password
                );
                bookmarkRequest.comment = Encryption.encrypt(
                    bookmarkRequest.comment,
                    password
                );

                const editedBookmark = await BookmarkClient.edit(
                    parseInt(match.params.id),
                    bookmarkRequest,
                    currentUser.token
                );
                addAlert(
                    successAlert(
                        `Bookmark with ID ${editedBookmark.id}`,
                        "edited"
                    )
                );
                history.push("/bookmarks");
            } catch (error: any) {
                console.error(error);
                addAlert(errorAlert(error.message));
            }
        }
    }
};
