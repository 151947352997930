import {
    Dispatch,
    Fragment,
    FunctionComponent,
    SetStateAction,
    useState,
} from "react";
import { BookmarkRecord } from "@secure-note/shared";
import { useStoreActions, useStoreState } from "../../store/_store";
import moment from "moment";
import { Card, CardBody, CardFooter } from "reactstrap";
import {
    ActionCardHeader,
    ActionsDropdown,
    ConfirmationModal,
} from "jack-hermanson-component-lib";
import { DATE_FORMAT } from "../../constants";
import { shortUrl } from "../../utils/shortUrl";
import {
    ClickDropdownAction,
    LinkDropdownAction,
    successAlert,
} from "jack-hermanson-ts-utils";
import { BookmarkClient } from "../../clients/BookmarkClient";
import { useHistory } from "react-router-dom";
import { BookmarkTag } from "./BookmarkTag";

interface Props {
    bookmark: BookmarkRecord;
    className?: string;
    setSelectedTagIds: Dispatch<SetStateAction<number[]>>;
    selectedTagIds: number[];
    onDelete: () => any;
}

export const BookmarkGlance: FunctionComponent<Props> = ({
    bookmark,
    className,
    selectedTagIds,
    setSelectedTagIds,
    onDelete,
}: Props) => {
    const tags = useStoreState(state => state.tags);
    const currentUser = useStoreState(state => state.currentUser);
    const addAlert = useStoreActions(actions => actions.addAlert);

    const created = moment(bookmark.created);
    const updated = moment(bookmark.updated);

    const history = useHistory();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <Fragment>
            <Card className={className || ""}>
                {renderCardHeader()}
                {renderCardBody()}
                {renderCardFooter()}
            </Card>
            {renderDeleteModal()}
        </Fragment>
    );

    function renderCardHeader() {
        return (
            <ActionCardHeader title={bookmark.title}>
                <ActionsDropdown
                    options={[
                        new ClickDropdownAction("Copy URL", async () => {
                            await navigator.clipboard.writeText(bookmark.url);
                        }),
                        undefined,
                        new LinkDropdownAction(
                            "Edit",
                            `/bookmarks/edit/${bookmark.id}`
                        ),
                        new ClickDropdownAction("Delete", () => {
                            setShowDeleteModal(true);
                        }),
                    ]}
                    size="sm"
                />
            </ActionCardHeader>
        );
    }

    function renderCardBody() {
        return (
            <CardBody className="pt-1">
                <div className="mb-2">
                    <small className="text-muted">
                        {created.format(DATE_FORMAT)}
                        {!created.isSame(updated) && (
                            <Fragment>
                                {" "}
                                (Updated {updated.format(DATE_FORMAT)})
                            </Fragment>
                        )}
                    </small>
                </div>
                <dl className="mb-0">
                    <dt>URL</dt>
                    <dd className="no-mb-last">
                        <a
                            href={bookmark.url}
                            target="_blank"
                            rel="noreferrer external"
                            onClick={event => {
                                if (
                                    bookmark.url.endsWith(".mp4") ||
                                    bookmark.url.endsWith(".m4v")
                                ) {
                                    event.preventDefault();
                                    history.push(
                                        `/bookmarks/embed/${bookmark.id}`
                                    );
                                }
                            }}
                        >
                            {shortUrl(bookmark.url)}
                        </a>
                    </dd>

                    {bookmark.comment.length > 0 && (
                        <Fragment>
                            <dt>Comment</dt>
                            <dd className="no-mb-last">{bookmark.comment}</dd>
                        </Fragment>
                    )}
                </dl>
            </CardBody>
        );
    }

    function renderCardFooter() {
        return (
            <Fragment>
                {bookmark.tagIds.length !== 0 && tags && (
                    <CardFooter className="pt-1">
                        <BookmarkTag
                            bookmark={bookmark}
                            selectedTagIds={selectedTagIds}
                            setSelectedTagIds={setSelectedTagIds}
                            tags={tags}
                        />
                    </CardFooter>
                )}
            </Fragment>
        );
    }

    function renderDeleteModal() {
        return (
            <ConfirmationModal
                isOpen={showDeleteModal}
                setIsOpen={setShowDeleteModal}
                title="Confirm Bookmark Deletion"
                onConfirm={deleteBookmark}
                buttonColor="danger"
                buttonText="Delete"
            >
                Are you sure you want to delete this bookmark?
            </ConfirmationModal>
        );
    }

    async function deleteBookmark() {
        if (currentUser?.token) {
            await BookmarkClient.delete(bookmark.id, currentUser.token);
            addAlert(
                successAlert(`bookmark with ID ${bookmark.id}`, "deleted")
            );
            onDelete();
        }
    }
};
