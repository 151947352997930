import { FunctionComponent } from "react";
import { PageHeader } from "jack-hermanson-component-lib";
import { Col, Row } from "reactstrap";
import { CreateEditTagForm } from "./CreateEditTagForm";
import { TagRequest } from "@secure-note/shared";
import { useStoreActions, useStoreState } from "../../store/_store";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { Encryption } from "../../utils/Encryption";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { useHistory } from "react-router-dom";
import { TagClient } from "../../clients/TagClient";

export const CreateTag: FunctionComponent = () => {
    useAuth();
    usePassword();

    const password = useStoreState(state => state.password);
    const currentUser = useStoreState(state => state.currentUser);
    const addAlert = useStoreActions(actions => actions.addAlert);
    const fetchTags = useStoreActions(actions => actions.fetchTags);

    const history = useHistory();

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Create Tag" />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    <CreateEditTagForm onSubmit={onSubmit} />
                </Col>
            </Row>
        );
    }

    async function onSubmit(tagRequest: TagRequest) {
        if (password && currentUser?.token) {
            try {
                tagRequest.name = Encryption.encrypt(tagRequest.name, password);
                const tag = await TagClient.create(
                    tagRequest,
                    currentUser.token
                );
                addAlert(successAlert(`tag with ID ${tag.id}`, "created"));
                await fetchTags(currentUser.token);
                history.push("/tags");
            } catch (error: any) {
                console.error(error);
                addAlert(errorAlert(error.message));
            } finally {
            }
        }
    }
};
