import { FunctionComponent } from "react";
import { PageHeader } from "jack-hermanson-component-lib";
import { Col, Row } from "reactstrap";
import { CreateEditSongForm } from "./CreateEditSongForm";
import { SongRequest } from "@secure-note/shared";
import { SongClient } from "../../clients/SongClient";
import { useStoreState } from "../../store/_store";
import { useHistory } from "react-router-dom";

export const CreateSong: FunctionComponent = () => {
    const currentUser = useStoreState(state => state.currentUser);

    const history = useHistory();

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Create Song"} />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    <CreateEditSongForm onSubmit={onSubmit} />
                </Col>
            </Row>
        );
    }

    async function onSubmit(songRequest: SongRequest) {
        if (currentUser?.token) {
            try {
                const newSong = await SongClient.create(
                    songRequest,
                    currentUser.token
                );
                history.push(`/songs/${newSong.id}`);
            } catch (error) {
                console.error(error);
            }
        }
    }
};
