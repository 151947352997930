import { Fragment, FunctionComponent, useCallback, useState } from "react";
import {
    Collapse,
    Container,
    Nav,
    NavbarBrand,
    NavbarToggler,
} from "reactstrap";
import { APP_NAME, CONTAINER_FLUID, NAV_ICON_CLASSES } from "../../constants";
import { FaBookmark, FaList, FaLock, FaMusic, FaTags } from "react-icons/all";
import { NavbarLink } from "./NavbarLink";
import { useHistory } from "react-router-dom";
import { useStoreState } from "../../store/_store";

export const Navigation: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = useCallback(() => setIsOpen(o => !o), [setIsOpen]);
    const close = useCallback(() => setIsOpen(false), [setIsOpen]);

    const currentUser = useStoreState(state => state.currentUser);

    const history = useHistory();

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-secondary mb-2">
            <Container fluid={CONTAINER_FLUID}>
                <NavbarBrand
                    id="navbar-brand"
                    className="d-flex hover-mouse"
                    onClick={() => {
                        history.push("/");
                    }}
                >
                    <div className="my-auto">{APP_NAME}</div>
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar style={{ marginRight: "auto" }}>
                        {currentUser && currentUser.verified && (
                            <Fragment>
                                <NavbarLink
                                    to="/notes"
                                    onClick={close}
                                    icon={
                                        <FaList className={NAV_ICON_CLASSES} />
                                    }
                                    text="Notes"
                                />
                                <NavbarLink
                                    to={"/bookmarks"}
                                    onClick={close}
                                    icon={
                                        <FaBookmark
                                            className={NAV_ICON_CLASSES}
                                        />
                                    }
                                    text="Bookmarks"
                                />
                                <NavbarLink
                                    to="/tags"
                                    onClick={close}
                                    icon={
                                        <FaTags className={NAV_ICON_CLASSES} />
                                    }
                                    text="Tags"
                                />
                                <NavbarLink
                                    to={"/songs"}
                                    onClick={close}
                                    icon={
                                        <FaMusic className={NAV_ICON_CLASSES} />
                                    }
                                    text={"Songs"}
                                />
                            </Fragment>
                        )}
                    </Nav>
                    <Nav navbar style={{ marginLeft: "auto" }}>
                        <NavbarLink
                            to="/auth"
                            onClick={close}
                            icon={<FaLock className={NAV_ICON_CLASSES} />}
                            text="Auth"
                        />
                    </Nav>
                </Collapse>
            </Container>
        </nav>
    );
};
