import {
    Dispatch,
    Fragment,
    FunctionComponent,
    SetStateAction,
    useState,
} from "react";
import { NoteRecord } from "@secure-note/shared";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { ActionsDropdown } from "jack-hermanson-component-lib";
import { useStoreState } from "../../store/_store";
import moment from "moment";
import { DATE_FORMAT, TRUNCATE_LENGTH } from "../../constants";
import {
    ClickDropdownAction,
    LinkDropdownAction,
} from "jack-hermanson-ts-utils";
import { converter } from "../../utils/markdown";
import { Link } from "react-router-dom";
import { ConfirmNoteDeletion } from "./ConfirmNoteDeletion";
import { TagBadge } from "../Tags/TagBadge";

interface Props {
    note: NoteRecord;
    className?: string;
    onDelete: () => void;
    selectedTagIds: number[];
    setSelectedTagIds: Dispatch<SetStateAction<number[]>>;
}

export const NoteGlance: FunctionComponent<Props> = ({
    note,
    className,
    onDelete,
    selectedTagIds,
    setSelectedTagIds,
}: Props) => {
    const tags = useStoreState(state => state.tags);

    const created = moment(note.created);
    const updated = moment(note.updated);

    const isTruncated: boolean = note.body.length > TRUNCATE_LENGTH;

    const [html, setHtml] = useState<string>(`
        ${converter.makeHtml(note.body.substring(0, TRUNCATE_LENGTH))} 
        ${isTruncated ? "[...]" : ""}
    `);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <Fragment>
            <Card className={className || ""}>
                <CardHeader className="d-flex">
                    <h5 className="me-auto my-auto">
                        <Link className="header-link" to={`/notes/${note.id}`}>
                            {note.title}
                        </Link>
                    </h5>
                    <ActionsDropdown
                        options={[
                            new LinkDropdownAction(
                                "Edit",
                                `/notes/edit/${note.id}`
                            ),
                            undefined,
                            new ClickDropdownAction("Delete", () => {
                                setShowDeleteModal(true);
                            }),
                        ]}
                        size="sm"
                    />
                </CardHeader>
                <CardBody className="pt-1">
                    <div className="mb-2">
                        <small className="text-muted">
                            {created.format(DATE_FORMAT)}
                            {!created.isSame(updated) && (
                                <Fragment>
                                    {" "}
                                    (Updated {updated.format(DATE_FORMAT)})
                                </Fragment>
                            )}
                        </small>
                    </div>
                    <div
                        className="note-body"
                        dangerouslySetInnerHTML={{
                            __html: html,
                        }}
                        onClick={() => {
                            if (isTruncated) {
                                setHtml(converter.makeHtml(note.body));
                            }
                        }}
                    />
                </CardBody>
                {note.tagIds.length !== 0 && tags && (
                    <CardFooter className="pt-1">
                        <div className="d-block">
                            {note.tagIds.map(tagId => (
                                <TagBadge
                                    key={tagId}
                                    tagId={tagId}
                                    selectedTagIds={selectedTagIds}
                                    setSelectedTagIds={setSelectedTagIds}
                                    tags={tags}
                                />
                            ))}
                        </div>
                    </CardFooter>
                )}
            </Card>
            {renderDeleteModal()}
        </Fragment>
    );

    function renderDeleteModal() {
        return (
            <ConfirmNoteDeletion
                note={note}
                callback={() => {
                    onDelete();
                }}
                isOpen={showDeleteModal}
                setIsOpen={setShowDeleteModal}
            />
        );
    }
};
