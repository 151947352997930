import { createStore, createTypedHooks } from "easy-peasy";
import { alertStore, AlertStoreModel } from "./alertStore";
import { accountStore, AccountStoreModel } from "./accountStore";
import { tagStore, TagStoreModel } from "./tagStore";
import { passwordStore, PasswordStoreModel } from "./passwordStore";

export interface StoreModel
    extends AlertStoreModel,
        AccountStoreModel,
        TagStoreModel,
        PasswordStoreModel {}

export const store = createStore<StoreModel>({
    ...alertStore,
    ...accountStore,
    ...tagStore,
    ...passwordStore,
} as StoreModel);

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreState = typedHooks.useStoreState;
