import { FunctionComponent } from "react";
import { PageHeader } from "jack-hermanson-component-lib";
import { CreateEditBookmarkForm } from "./CreateEditBookmarkForm";
import { Col, Row } from "reactstrap";
import { BookmarkRequest } from "@secure-note/shared";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { useStoreActions, useStoreState } from "../../store/_store";
import { Encryption } from "../../utils/Encryption";
import { BookmarkClient } from "../../clients/BookmarkClient";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { useHistory } from "react-router-dom";

export const CreateBookmark: FunctionComponent = () => {
    useAuth();
    usePassword();

    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);

    const addAlert = useStoreActions(actions => actions.addAlert);

    const history = useHistory();

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Create Bookmark"} />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    <CreateEditBookmarkForm onSubmit={onSubmit} />
                </Col>
            </Row>
        );
    }

    async function onSubmit(bookmarkRequest: BookmarkRequest) {
        if (currentUser?.token && password) {
            try {
                bookmarkRequest.title = Encryption.encrypt(
                    bookmarkRequest.title,
                    password
                );
                bookmarkRequest.url = Encryption.encrypt(
                    bookmarkRequest.url,
                    password
                );
                bookmarkRequest.comment = Encryption.encrypt(
                    bookmarkRequest.comment,
                    password
                );

                const bookmark = await BookmarkClient.create(
                    bookmarkRequest,
                    currentUser.token
                );
                addAlert(
                    successAlert(`bookmark with ID ${bookmark.id}`, "created")
                );
                history.push("/bookmarks");
            } catch (error: any) {
                addAlert(errorAlert(error.message));
            }
        }
    }
};
