import { AccountRecord, NoteRecord, NoteRequest } from "@secure-note/shared";
import axios from "axios";
import {
    AggregateRequest,
    AggregateResourceModel,
    getAuthHeader,
} from "jack-hermanson-ts-utils";

export interface GetNotesRequest extends AggregateRequest {
    account: AccountRecord;
    tagIds: number[];
}

export abstract class NoteClient {
    private static baseUrl = "/api/notes";

    static async getAll({ account, tagIds, skip, take }: GetNotesRequest) {
        const response = await axios.get<AggregateResourceModel<NoteRecord>>(
            this.baseUrl,
            {
                ...getAuthHeader(account.token!),
                params: { tagIds: tagIds, skip, take },
            }
        );
        return response.data;
    }

    static async getOne(id: number, token: string) {
        const response = await axios.get<NoteRecord>(
            `${this.baseUrl}/${id}`,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async create(noteRequest: NoteRequest, token: string) {
        const response = await axios.post<NoteRecord>(
            this.baseUrl,
            noteRequest,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async edit(noteRequest: NoteRequest, id: number, token: string) {
        const response = await axios.put<NoteRecord>(
            `${this.baseUrl}/${id}`,
            noteRequest,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async delete(id: number, token: string) {
        const response = await axios.delete<boolean>(
            `${this.baseUrl}/${id}`,
            getAuthHeader(token)
        );
        return response.data;
    }
}
