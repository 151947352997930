export abstract class LocalStorage {
    private static tokenKey = "token";
    private static redirectKey = "redirectPath";

    static saveToken(token: string): void {
        localStorage.setItem(this.tokenKey, token);
    }

    static getToken(): string | undefined {
        return localStorage.getItem(this.tokenKey) || undefined;
    }

    static removeToken(): void {
        localStorage.removeItem(this.tokenKey);
    }

    static getRedirectPath(): string | undefined {
        const redirectPath = localStorage.getItem(this.redirectKey);
        if (redirectPath) {
            return redirectPath;
        }
        return undefined;
    }

    static saveRedirectPath(path: string) {
        localStorage.setItem(this.redirectKey, path);
    }

    static removeRedirectPath(): void {
        localStorage.removeItem(this.redirectKey);
    }
}
