import { useStoreState } from "../store/_store";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { LocalStorage } from "./LocalStorage";

export const usePassword = () => {
    const history = useHistory();

    const password = useStoreState(state => state.password);

    useEffect(() => {
        if (!password) {
            if (window.location.pathname !== "/auth/login") {
                LocalStorage.saveRedirectPath(window.location.pathname);
                console.log(
                    `Saving to redirect path "${window.location.pathname}"`
                );
            }
            history.replace("/auth/password");
        }
    }, [password, history]);
};
