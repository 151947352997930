import { FunctionComponent } from "react";
import { Col, Row } from "reactstrap";
import { NavTabs } from "jack-hermanson-component-lib";

export const AuthTabs: FunctionComponent = () => {
    return (
        <Row>
            <Col>
                <NavTabs
                    links={[
                        {
                            path: "/auth",
                            text: "User Authentication",
                            exact: true,
                        },
                        {
                            path: "/auth/password",
                            text: "Encryption Password",
                        },
                        {
                            path: "/auth/re-encrypt-all",
                            text: "Re-encrypt All",
                        },
                    ]}
                />
            </Col>
        </Row>
    );
};
