import axios from "axios";
import {
    AccountRecord,
    BookmarkRecord,
    BookmarkRequest,
} from "@secure-note/shared";
import { getAuthHeader } from "jack-hermanson-ts-utils";

export abstract class BookmarkClient {
    private static baseUrl = "/api/bookmarks";

    static async getAll(account: AccountRecord, tagIds: number[]) {
        const response = await axios.get<BookmarkRecord[]>(this.baseUrl, {
            ...getAuthHeader(account.token!),
            params: { tagIds },
        });
        return response.data;
    }

    static async create(bookmarkRequest: BookmarkRequest, token: string) {
        try {
            const response = await axios.post<BookmarkRecord>(
                this.baseUrl,
                bookmarkRequest,
                getAuthHeader(token)
            );
            return response.data;
        } catch (error: any) {
            console.error(error.response);
            throw error;
        }
    }

    static async getOne(id: number, token: string) {
        const response = await axios.get<BookmarkRecord>(
            `${this.baseUrl}/${id}`,
            getAuthHeader(token)
        );
        return response.data;
    }

    static async edit(
        id: number,
        bookmarkRequest: BookmarkRequest,
        token: string
    ) {
        try {
            const response = await axios.put<BookmarkRecord>(
                `${this.baseUrl}/${id}`,
                bookmarkRequest,
                getAuthHeader(token)
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async delete(id: number, token: string) {
        try {
            const response = await axios.delete<boolean>(
                `${this.baseUrl}/${id}`,
                getAuthHeader(token)
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
