import { Layout } from "./components/Layout/Layout";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Alerts } from "./components/Alerts/Alerts";
import { AuthIndex } from "./components/Auth/AuthIndex";
import { useStoreActions, useStoreState } from "./store/_store";
import { useEffect } from "react";
import { LocalStorage } from "./utils/LocalStorage";
import { AccountClient } from "./clients/AccountClient";
import { LandingPage } from "./components/LandingPage";
import { RegisterPage } from "./components/Auth/RegisterPage";
import { LoginPage } from "./components/Auth/LoginPage";
import { Forbidden } from "./components/Errors/Forbidden";
import { NotFound } from "./components/Errors/NotFound";
import { NotesIndex } from "./components/Notes/NotesIndex";
import { BookmarksIndex } from "./components/Bookmarks/BookmarksIndex";
import { TagsIndex } from "./components/Tags/TagsIndex";
import { CreateNote } from "./components/Notes/CreateNote";
import { PasswordPage } from "./components/Auth/PasswordPage";
import { CreateTag } from "./components/Tags/CreateTag";
import { CreateBookmark } from "./components/Bookmarks/CreateBookmark";
import { EditTag } from "./components/Tags/EditTag";
import { EditNote } from "./components/Notes/EditNote";
import { EditBookmark } from "./components/Bookmarks/EditBookmark";
import { NoteDetails } from "./components/Notes/NoteDetails";
import { SongsIndex } from "./components/Songs/SongsIndex";
import { EmbedMedia } from "./components/Bookmarks/EmbedMedia";
import { CreateSong } from "./components/Songs/CreateSong";
import { SongDetails } from "./components/Songs/SongDetails";
import { EditSong } from "./components/Songs/EditSong";
import { ReEncryptAllPage } from "./components/Auth/ReEncryptAllPage";

function App() {
    const setCurrentUser = useStoreActions(actions => actions.setCurrentUser);
    const fetchTags = useStoreActions(actions => actions.fetchTags);
    const password = useStoreState(state => state.password);

    useEffect(() => {
        console.log("App useEffect()");
        const token = LocalStorage.getToken();
        if (token) {
            AccountClient.logInWithToken({
                token,
            })
                .then(account => {
                    setCurrentUser(account);
                    if (account.token && password) {
                        fetchTags(account.token);
                    }
                })
                .catch(error => {
                    console.log("Error logging in with token");
                    console.log(error);
                });
        }
    }, [setCurrentUser, fetchTags, password]);

    return (
        <BrowserRouter>
            <Layout>
                <Alerts />
                <Switch>
                    <Route exact path="/" component={LandingPage} />

                    {/* Auth */}
                    <Route exact path="/auth" component={AuthIndex} />
                    <Route
                        exact
                        path="/auth/register"
                        component={RegisterPage}
                    />
                    <Route exact path="/auth/login" component={LoginPage} />
                    <Route
                        exact
                        path="/auth/re-encrypt-all"
                        component={ReEncryptAllPage}
                    />

                    {/* Password */}
                    <Route
                        exact
                        path="/auth/password"
                        component={PasswordPage}
                    />

                    {/* Notes */}
                    <Route exact path="/notes" component={NotesIndex} />
                    <Route exact path="/notes/new" component={CreateNote} />
                    <Route exact path="/notes/:id" component={NoteDetails} />
                    <Route exact path="/notes/edit/:id" component={EditNote} />

                    {/* Bookmarks */}
                    <Route exact path="/bookmarks" component={BookmarksIndex} />
                    <Route
                        exact
                        path="/bookmarks/new"
                        component={CreateBookmark}
                    />
                    <Route
                        exact
                        path="/bookmarks/edit/:id"
                        component={EditBookmark}
                    />
                    <Route
                        exact
                        path="/bookmarks/embed/:id"
                        component={EmbedMedia}
                    />

                    {/* Tags */}
                    <Route exact path="/tags" component={TagsIndex} />
                    <Route exact path="/tags/new" component={CreateTag} />
                    <Route exact path="/tags/edit/:id" component={EditTag} />

                    {/* Songs */}
                    <Route exact path="/songs" component={SongsIndex} />
                    <Route exact path="/songs/new" component={CreateSong} />
                    <Route exact path="/songs/:id" component={SongDetails} />
                    <Route exact path="/songs/edit/:id" component={EditSong} />

                    {/* Errors */}
                    <Route exact path="/forbidden" component={Forbidden} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
