import { StoreModel } from "./_store";
import { action, Action, thunk, Thunk } from "easy-peasy";
import {
    AccountRecord,
    RegisterRequest,
    TokenLoginRequest,
} from "@secure-note/shared";
import { LocalStorage } from "../utils/LocalStorage";
import { AccountClient } from "../clients/AccountClient";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";

export interface AccountStoreModel {
    currentUser: AccountRecord | undefined;
    setCurrentUser: Action<StoreModel, AccountRecord | undefined>;
    logInWithPassword: Thunk<StoreModel, RegisterRequest>;
    logInWithToken: Thunk<StoreModel, TokenLoginRequest>;
    logOut: Thunk<StoreModel, string>;
}

export const accountStore: AccountStoreModel = {
    currentUser: undefined,
    setCurrentUser: action((state, payload) => {
        state.currentUser = payload;
    }),
    logInWithPassword: thunk(async (actions, payload) => {
        try {
            const account = await AccountClient.logInWithPassword(payload);
            actions.setCurrentUser(account);
            if (account.token) {
                // Should always be the case.
                LocalStorage.saveToken(account.token);
            } else {
                throw new Error("Missing token");
            }
        } catch (error: any) {
            console.error(error);
            console.error(error.response);
            actions.addAlert(errorAlert("Failed to log in."));
            throw error;
        }
    }),
    logInWithToken: thunk(async (actions, payload) => {
        try {
            const account = await AccountClient.logInWithToken(payload);
            actions.setCurrentUser(account);
        } catch (error: any) {
            // This is okay. Token may have expired or been replaced.
            console.error(error);
            console.error(error.response);
            LocalStorage.removeToken();
        }
    }),
    logOut: thunk(async (actions, payload) => {
        try {
            const loggedOut = await AccountClient.logOut(payload);
            if (loggedOut) {
                actions.setCurrentUser(undefined);
                actions.setTags(undefined);
                actions.setPassword(undefined);
                LocalStorage.removeToken();
                actions.addAlert(successAlert("account", "logged out"));
            }
        } catch (error: any) {
            actions.addAlert(error.message);
            console.error(error);
            console.error(error.response);
        }
    }),
};
