import { action, Action } from "easy-peasy";
import { StoreModel } from "./_store";

export interface PasswordStoreModel {
    password: string | undefined;
    setPassword: Action<StoreModel, string | undefined>;
}

export const passwordStore: PasswordStoreModel = {
    password: undefined,
    setPassword: action((state, payload) => {
        state.password = payload;
    }),
};
