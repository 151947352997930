import { Fragment, FunctionComponent, useState } from "react";
import { Field, Form, Formik, FormikErrors, FormikProps } from "formik";
import * as yup from "yup";
import { FormError, LoadingSpinner } from "jack-hermanson-component-lib";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { RESET_BUTTON_COLOR, SUBMIT_BUTTON_COLOR } from "../../constants";
import { NoteRecord, NoteRequest } from "@secure-note/shared";
import { useStoreState } from "../../store/_store";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { converter } from "../../utils/markdown";
import { TagsField } from "../Tags/TagsField";
import { useDebounce } from "use-debounce";
import { SetFieldValue } from "../../utils/SetFieldValue";

interface Props {
    onSubmit: (noteRequest: NoteRequest) => Promise<any>;
    existingNote?: NoteRecord;
}

const validationSchema = yup.object().shape({
    title: yup.string().label("Title").required(),
    body: yup.string().label("Body").required(),
    tagIds: yup.array().label("Tags"),
});

interface FormValues {
    title: string;
    body: string;
    tagIds: Array<string>;
}

export const CreateEditNoteForm: FunctionComponent<Props> = ({
    onSubmit,
    existingNote,
}: Props) => {
    const tags = useStoreState(state => state.tags)?.filter(t => t.forNotes);

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
        "write"
    );

    const [bodyText, setBodyText] = useState("");
    const [debouncedBodyText] = useDebounce(bodyText, 1000);

    return (
        <Formik
            initialValues={{
                title: existingNote?.title || "",
                body: existingNote?.body || "",
                tagIds: (existingNote?.tagIds?.map(t => t.toString()) ||
                    []) as Array<string>,
            }}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmit({
                    title: data.title,
                    body: data.body,
                    tagIds: data.tagIds.map(s => parseInt(s)),
                });
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                errors,
                isSubmitting,
                setFieldValue,
                values,
            }: FormikProps<FormValues>) => (
                <Form>
                    {isSubmitting ? (
                        <LoadingSpinner />
                    ) : (
                        <Fragment>
                            {renderTitle(errors)}
                            {renderBody(values, setFieldValue, errors)}
                            {renderTags(values, setFieldValue, errors)}
                            {renderButtons()}
                        </Fragment>
                    )}
                </Form>
            )}
        </Formik>
    );

    function renderTitle(errors: FormikErrors<FormValues>) {
        const id = "title-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Title
                </Label>
                <Field
                    name="title"
                    id={id}
                    type="text"
                    as={Input}
                    autoFocus={true}
                />
                <FormError>{errors.title}</FormError>
            </FormGroup>
        );
    }

    function renderBody(
        values: FormValues,
        setFieldValue: SetFieldValue,
        errors: FormikErrors<FormValues>
    ) {
        const id = "body-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Body
                </Label>
                <ReactMde
                    value={values.body}
                    onChange={value => {
                        setFieldValue("body", value);
                        setBodyText(value);
                    }}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={markdown =>
                        Promise.resolve(
                            converter.makeHtml(debouncedBodyText) || markdown
                        )
                    }
                    initialEditorHeight={250}
                />
                <FormError>{errors.body}</FormError>
            </FormGroup>
        );
    }

    function renderTags(
        values: FormValues,
        setFieldValue: SetFieldValue,
        errors: FormikErrors<FormValues>
    ) {
        if (tags) {
            return (
                <TagsField
                    tags={tags}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                />
            );
        }
    }

    function renderButtons() {
        return (
            <div className="bottom-buttons">
                <Button type="submit" color={SUBMIT_BUTTON_COLOR}>
                    Save
                </Button>
                <Button type="reset" color={RESET_BUTTON_COLOR}>
                    Reset
                </Button>
            </div>
        );
    }
};
