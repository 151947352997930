import { FunctionComponent, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { ActionCardHeader, PageHeader } from "jack-hermanson-component-lib";
import { AuthTabs } from "./AuthTabs";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { SUBMIT_BUTTON_COLOR } from "../../constants";
import { useStoreActions, useStoreState } from "../../store/_store";
import { BookmarkRecord, NoteRecord, TagRecord } from "@secure-note/shared";
import { NoteClient } from "../../clients/NoteClient";
import { Encryption } from "../../utils/Encryption";
import { TagClient } from "../../clients/TagClient";
import { BookmarkClient } from "../../clients/BookmarkClient";
import { ProgressBar } from "../Utils/ProgressBar";
import { PasswordInput } from "../Utils/PasswordInput";

export const ReEncryptAllPage: FunctionComponent = () => {
    useAuth();
    usePassword();

    const currentPassword = useStoreState(state => state.password);
    const currentUser = useStoreState(state => state.currentUser);

    const setCurrentPassword = useStoreActions(actions => actions.setPassword);
    const setAlerts = useStoreActions(actions => actions.setAlerts);

    const [newPassword, setNewPassword] = useState<string>("");

    const [notes, setNotes] = useState<NoteRecord[] | undefined>(undefined);
    const [updatedNotesCount, setUpdatedNotesCount] = useState(0);

    const [tags, setTags] = useState<TagRecord[] | undefined>(undefined);
    const [updatedTagsCount, setUpdatedTagsCount] = useState(0);

    const [bookmarks, setBookmarks] = useState<BookmarkRecord[] | undefined>(
        undefined
    );
    const [updatedBookmarksCount, setUpdatedBookmarksCount] = useState(0);
    const [done, setDone] = useState(false);

    // get notes
    useEffect(() => {
        if (currentUser && currentPassword) {
            NoteClient.getAll({
                account: currentUser,
                tagIds: [],
                skip: 0,
                take: 0,
            }).then(data => {
                console.log("data", data);
                setNotes(Encryption.decryptNotes(data.items, currentPassword));
            });
        }
    }, [setNotes, currentUser, currentPassword]);

    // get tags
    useEffect(() => {
        if (currentUser?.token && currentPassword) {
            TagClient.getAll(currentUser.token).then(data => {
                setTags(
                    data.map(encryptedTag => ({
                        ...encryptedTag,
                        name: Encryption.decrypt(
                            encryptedTag.name,
                            currentPassword
                        ),
                    }))
                );
            });
        }
    }, [currentUser?.token, currentPassword, setTags]);

    // get bookmarks
    useEffect(() => {
        if (currentUser && currentPassword) {
            BookmarkClient.getAll(currentUser, []).then(data => {
                setBookmarks(
                    Encryption.decryptBookmarks(data, currentPassword)
                );
            });
        }
    }, [setBookmarks, currentPassword, currentUser]);

    // do notes and tags and bookmarks
    function reEncryptAll() {
        reEncryptAllNotes();
        reEncryptAllTags();
        reEncryptAllBookmarks();
        setAlerts([
            {
                text: "All notes, tags, and bookmarks re-encrypted with new key.",
                color: "success",
            },
        ]);
        setCurrentPassword(newPassword);
        setDone(true);
    }

    // do all the notes one by one
    function reEncryptAllNotes() {
        if (notes && currentUser?.token && currentPassword) {
            for (const existingNote of notes) {
                NoteClient.edit(
                    {
                        tagIds: existingNote.tagIds,
                        title: Encryption.encrypt(
                            existingNote.title,
                            newPassword
                        ),
                        body: Encryption.encrypt(
                            existingNote.body,
                            newPassword
                        ),
                    },
                    existingNote.id,
                    currentUser.token
                ).then(() => {
                    // It was successful, increase the count
                    setUpdatedNotesCount(c => c + 1);
                });
            }
        }
    }

    // do all the tags one by one
    function reEncryptAllTags() {
        if (tags && currentUser?.token && currentPassword) {
            for (const existingTag of tags) {
                TagClient.edit(
                    existingTag.id,
                    {
                        name: Encryption.encrypt(existingTag.name, newPassword),
                        forBookmarks: existingTag.forBookmarks,
                        forNotes: existingTag.forNotes,
                    },
                    currentUser.token
                ).then(() => {
                    setUpdatedTagsCount(c => c + 1);
                });
            }
        }
    }

    // do all bookmarks one by one
    function reEncryptAllBookmarks() {
        if (bookmarks && currentPassword && currentUser?.token) {
            for (const existingBookmark of bookmarks) {
                BookmarkClient.edit(
                    existingBookmark.id,
                    {
                        title: Encryption.encrypt(
                            existingBookmark.title,
                            newPassword
                        ),
                        tagIds: existingBookmark.tagIds,
                        url: Encryption.encrypt(
                            existingBookmark.url,
                            newPassword
                        ),
                        comment: Encryption.encrypt(
                            existingBookmark.comment,
                            newPassword
                        ),
                    },
                    currentUser.token
                ).then(() => {
                    setUpdatedBookmarksCount(c => c + 1);
                });
            }
        }
    }

    return (
        <div>
            <AuthTabs />
            {renderPageHeader()}
            {renderNewPasswordCard()}
            {renderProgressCard()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Re-encrypt Everything"} />
                </Col>
            </Row>
        );
    }

    function renderProgressCard() {
        return (
            <Row>
                <Col>
                    <Card>
                        <ActionCardHeader title={"Re-encryption progress"} />
                        <CardBody>
                            <p>Total notes: {notes?.length ?? 0}</p>
                            <p>Re-encrypted notes: {updatedNotesCount}</p>
                            <div>
                                <ProgressBar
                                    count={updatedNotesCount}
                                    total={notes?.length}
                                />
                            </div>

                            <hr />
                            <p>Total tags: {tags?.length ?? 0}</p>
                            <p>Re-encrypted tags: {updatedTagsCount}</p>
                            <div>
                                <ProgressBar
                                    count={updatedTagsCount}
                                    total={tags?.length}
                                />
                            </div>
                            <hr />
                            <p>Total bookmarks: {bookmarks?.length ?? 0}</p>
                            <p>
                                Re-encrypted bookmarks: {updatedBookmarksCount}
                            </p>
                            <div>
                                <ProgressBar
                                    count={updatedBookmarksCount}
                                    total={bookmarks?.length}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    function renderNewPasswordCard() {
        return (
            <Row className="mb-3">
                <Col>
                    <Card>
                        <ActionCardHeader title={"New Password"} />
                        <CardBody>
                            <p>
                                New encryption password will be used to
                                re-encrypt all available notes, bookmarks, and
                                tags. Very blunt tool.
                            </p>
                            <hr />
                            <FormGroup>
                                <Label className="required">New Password</Label>
                                <PasswordInput
                                    disabled={done}
                                    autoFocus
                                    value={newPassword}
                                    onChange={e =>
                                        setNewPassword(e.target.value)
                                    }
                                    placeholder="New Encryption Key"
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            reEncryptAll();
                                        }
                                    }}
                                />
                            </FormGroup>
                            <Button
                                className={`btn-${SUBMIT_BUTTON_COLOR}`}
                                onClick={reEncryptAll}
                                disabled={done}
                            >
                                Re-encrypt
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
};
