import { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { useSong } from "../../hooks/useSong";
import { ActionsDropdown, PageHeader } from "jack-hermanson-component-lib";
import {
    ClickDropdownAction,
    LinkDropdownAction,
} from "jack-hermanson-ts-utils";
import { Col, Row } from "reactstrap";
import { converter } from "../../utils/markdown";

interface Props extends RouteComponentProps<{ id: string }> {}

export const SongDetails: FunctionComponent<Props> = ({ match }: Props) => {
    useAuth();
    usePassword();

    const { song } = useSong(parseInt(match.params.id));

    return (
        <div>
            {song ? (
                <div>
                    {renderPageHeader()}
                    {renderNotes()}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );

    function renderPageHeader() {
        if (song)
            return (
                <Row>
                    <Col>
                        <PageHeader title={song.name}>
                            <ActionsDropdown
                                options={[
                                    new LinkDropdownAction(
                                        "Edit",
                                        `/songs/edit/${song.id}`
                                    ),
                                    undefined,
                                    new ClickDropdownAction("Delete", () => {
                                        console.log("delete");
                                    }),
                                ]}
                                size="sm"
                            />
                        </PageHeader>
                    </Col>
                </Row>
            );
    }

    function renderNotes() {
        if (song) {
            return (
                <Row>
                    <Col>
                        {!song.notes ? (
                            <p className="fst-italic mb-0 text-muted">
                                No notes.
                            </p>
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(song.notes),
                                }}
                            />
                        )}
                    </Col>
                </Row>
            );
        }
    }
};
