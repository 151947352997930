export const CONTAINER_FLUID = false;
export const APP_NAME = "SecureNote";
export const NAV_ICON_CLASSES = "me-2 my-auto";
export const NEW_BUTTON_COLOR = "success";
export const BUTTON_ICON_CLASSES = "me-1 my-auto";
export const SUBMIT_BUTTON_COLOR = "primary";
export const RESET_BUTTON_COLOR = "secondary";
export const DATE_FORMAT = "MM/DD/YY, h:mm a";
export const LONG_DATE_FORMAT = `dddd, MMMM Do, YYYY [at] hh:mm a`;
export const TRUNCATE_LENGTH = 100;
export const DEFAULT_SKIP_TAKE = 10;
