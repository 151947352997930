import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Badge, Col, Row } from "reactstrap";
import {
    ActionsDropdown,
    LoadingSpinner,
    PageHeader,
} from "jack-hermanson-component-lib";
import { NoteRecord } from "@secure-note/shared";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { useStoreState } from "../../store/_store";
import { NoteClient } from "../../clients/NoteClient";
import { Encryption } from "../../utils/Encryption";
import {
    ClickDropdownAction,
    LinkDropdownAction,
} from "jack-hermanson-ts-utils";
import { converter } from "../../utils/markdown";
import moment from "moment";
import { DATE_FORMAT } from "../../constants";
import { ConfirmNoteDeletion } from "./ConfirmNoteDeletion";

interface Props extends RouteComponentProps<{ id: string }> {}

export const NoteDetails: FunctionComponent<Props> = ({ match }: Props) => {
    useAuth();
    usePassword();

    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);
    const tags = useStoreState(state => state.tags);

    const [note, setNote] = useState<NoteRecord | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (currentUser?.token && password) {
            NoteClient.getOne(
                parseInt(match.params.id),
                currentUser.token
            ).then(data => {
                setNote({
                    ...data,
                    title: Encryption.decrypt(data.title, password),
                    body: Encryption.decrypt(data.body, password),
                });
            });
        }
    }, [currentUser, password, setNote, match.params.id]);

    return (
        <div>
            {renderPageHeader()}
            {renderBody()}
            {renderTags()}
            {renderDates()}
            {renderDeleteModal()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={note ? note.title : "Note Details"}>
                        <ActionsDropdown
                            options={[
                                new LinkDropdownAction(
                                    "Edit",
                                    `/notes/edit/${match.params.id}`
                                ),
                                undefined,
                                new ClickDropdownAction("Delete", () => {
                                    setShowDeleteModal(true);
                                }),
                            ]}
                            size="sm"
                        />
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderBody() {
        return (
            <Row>
                <Col>
                    {note ? (
                        <div
                            className="note-body note-body-detail"
                            dangerouslySetInnerHTML={{
                                __html: converter.makeHtml(note.body),
                            }}
                        />
                    ) : (
                        <LoadingSpinner />
                    )}
                </Col>
            </Row>
        );
    }

    function renderTags() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <div className="border-top my-3" />
                        Tags:
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {tags && note ? (
                            <small>
                                {note.tagIds.map(tagId => (
                                    <Badge
                                        key={tagId}
                                        color="secondary"
                                        className="me-1"
                                    >
                                        {tags.find(t => t.id === tagId)?.name}
                                    </Badge>
                                ))}
                            </small>
                        ) : (
                            <LoadingSpinner />
                        )}
                    </Col>
                </Row>
            </Fragment>
        );
    }

    function renderDates() {
        if (note) {
            const created = moment(note.created);
            const updated = moment(note.updated);
            return (
                <Fragment>
                    <Row className="mt-3">
                        <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                            <div className="d-block">Created:</div>
                            <div>{created.format(DATE_FORMAT)}</div>
                        </Col>
                        {!created.isSame(updated) && (
                            <Col xs={12} lg={6}>
                                <div className="d-block">Updated:</div>
                                <div>{updated.format(DATE_FORMAT)}</div>
                            </Col>
                        )}
                    </Row>
                </Fragment>
            );
        }
    }

    function renderDeleteModal() {
        if (note) {
            return (
                <ConfirmNoteDeletion
                    note={note}
                    callback={() => {
                        history.push("/notes");
                    }}
                    isOpen={showDeleteModal}
                    setIsOpen={setShowDeleteModal}
                />
            );
        }
    }
};
