import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { useStoreActions, useStoreState } from "../../store/_store";
import { Col, Row } from "reactstrap";
import { PageHeader } from "jack-hermanson-component-lib";
import { NoteRecord, NoteRequest } from "@secure-note/shared";
import { NoteClient } from "../../clients/NoteClient";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { CreateEditNoteForm } from "./CreateEditNoteForm";
import { Encryption } from "../../utils/Encryption";

interface Props extends RouteComponentProps<{ id: string }> {}

export const EditNote: FunctionComponent<Props> = ({ match }: Props) => {
    useAuth();
    usePassword();

    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);
    const addAlert = useStoreActions(actions => actions.addAlert);

    const history = useHistory();

    const [note, setNote] = useState<NoteRecord | undefined>(undefined);

    useEffect(() => {
        if (password && currentUser?.token) {
            NoteClient.getOne(
                parseInt(match.params.id),
                currentUser.token
            ).then(data => {
                setNote(Encryption.decryptNote(data, password));
            });
        }
    }, [setNote, match.params.id, password, currentUser]);

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Edit Note" />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        if (note) {
            return (
                <Row>
                    <Col>
                        <CreateEditNoteForm
                            existingNote={note}
                            onSubmit={onSubmit}
                        />
                    </Col>
                </Row>
            );
        }
    }

    async function onSubmit(noteRequest: NoteRequest) {
        if (currentUser?.token && password) {
            try {
                noteRequest.title = Encryption.encrypt(
                    noteRequest.title,
                    password
                );
                noteRequest.body = Encryption.encrypt(
                    noteRequest.body,
                    password
                );
                await NoteClient.edit(
                    noteRequest,
                    parseInt(match.params.id),
                    currentUser.token
                );
                addAlert(
                    successAlert(`note with ID ${match.params.id}`, "edited")
                );
                history.push(`/notes/${match.params.id}`);
            } catch (error: any) {
                addAlert(errorAlert(error.message));
            }
        }
    }
};
