import { FunctionComponent, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { PageHeader } from "jack-hermanson-component-lib";
import { RegisterRequest } from "@secure-note/shared";
import { RegisterForm } from "./RegisterForm";
import { useStoreActions, useStoreState } from "../../store/_store";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { AccountClient } from "../../clients/AccountClient";
import { LocalStorage } from "../../utils/LocalStorage";

export const RegisterPage: FunctionComponent = () => {
    const history = useHistory();

    const currentUser = useStoreState(state => state.currentUser);
    const addAlert = useStoreActions(actions => actions.addAlert);
    const setCurrentUser = useStoreActions(actions => actions.setCurrentUser);

    useEffect(() => {
        if (currentUser) {
            console.log("Already logged in");
            history.replace("/auth");
        }
    }, [history, currentUser]);

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Register" />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    <RegisterForm onSubmit={onSubmit} />
                </Col>
            </Row>
        );
    }

    async function onSubmit(registerRequest: RegisterRequest) {
        try {
            const account = await AccountClient.register(registerRequest);
            setCurrentUser(account);
            if (account.token) {
                LocalStorage.saveToken(account.token);
            }
            addAlert(successAlert(`user "${account.username}"`, "registered"));
            history.push("/auth");
        } catch (error: any) {
            console.error(error);
            addAlert(errorAlert(error.message));
        }
    }
};
