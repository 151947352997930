import { CreateEditNoteForm } from "./CreateEditNoteForm";
import { FunctionComponent } from "react";
import { NoteRequest } from "@secure-note/shared";
import { Col, Row } from "reactstrap";
import { PageHeader } from "jack-hermanson-component-lib";
import { useAuth } from "../../utils/useAuth";
import { useStoreActions, useStoreState } from "../../store/_store";
import { useHistory } from "react-router-dom";
import { NoteClient } from "../../clients/NoteClient";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { usePassword } from "../../utils/usePassword";
import { Encryption } from "../../utils/Encryption";

export const CreateNote: FunctionComponent = () => {
    useAuth();
    usePassword();

    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);
    const addAlert = useStoreActions(actions => actions.addAlert);

    const history = useHistory();

    return (
        <div>
            {renderPageTitle()}
            {renderForm()}
        </div>
    );

    function renderPageTitle() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Create Note"} />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    <CreateEditNoteForm onSubmit={onSubmit} />
                </Col>
            </Row>
        );
    }

    async function onSubmit(noteRequest: NoteRequest) {
        if (currentUser?.token && password) {
            try {
                noteRequest.title = Encryption.encrypt(
                    noteRequest.title,
                    password
                );
                noteRequest.body = Encryption.encrypt(
                    noteRequest.body,
                    password
                );
                const note = await NoteClient.create(
                    noteRequest,
                    currentUser.token
                );
                addAlert(successAlert(`note with ID ${note.id}`, "created"));
                history.push("/notes");
            } catch (error: any) {
                addAlert(errorAlert(error.message));
            }
        }
    }
};
