import { Fragment, FunctionComponent } from "react";
import { RegisterRequest } from "@secure-note/shared";
import * as yup from "yup";
import { Field, Form, Formik, FormikErrors, FormikProps } from "formik";
import { FormError, LoadingSpinner } from "jack-hermanson-component-lib";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { RESET_BUTTON_COLOR, SUBMIT_BUTTON_COLOR } from "../../constants";

interface Props {
    onSubmit: (registerRequest: RegisterRequest) => Promise<any>;
}

const validationSchema = yup.object().shape({
    username: yup.string().label("Username").required().min(2).max(25),
    password: yup.string().label("Password").required().min(4).max(100),
    confirmPassword: yup
        .string()
        .label("Confirm Password")
        .required()
        .test("passwords-match", "Passwords must match", function (value) {
            return this.parent.password === value;
        }),
});

interface FormValues extends RegisterRequest {
    confirmPassword: string;
}

export const RegisterForm: FunctionComponent<Props> = ({ onSubmit }: Props) => {
    return (
        <Formik
            initialValues={{
                username: "",
                password: "",
                confirmPassword: "",
            }}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmit({
                    username: data.username,
                    password: data.password,
                });
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, isSubmitting }: FormikProps<FormValues>) => (
                <Form>
                    {isSubmitting ? (
                        <LoadingSpinner />
                    ) : (
                        <Fragment>
                            {renderUsername(errors)}
                            {renderPassword(errors)}
                            {renderConfirmPassword(errors)}
                            {renderButtons()}
                        </Fragment>
                    )}
                </Form>
            )}
        </Formik>
    );

    function renderUsername(errors: FormikErrors<FormValues>) {
        const id = "username-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Username
                </Label>
                <Field
                    name="username"
                    id={id}
                    type="text"
                    as={Input}
                    autoFocus={true}
                />
                <FormError>{errors.username}</FormError>
            </FormGroup>
        );
    }

    function renderPassword(errors: FormikErrors<FormValues>) {
        const id = "password-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Password
                </Label>
                <Field name="password" id={id} type="password" as={Input} />
                <FormError>{errors.password}</FormError>
            </FormGroup>
        );
    }

    function renderConfirmPassword(errors: FormikErrors<FormValues>) {
        const id = "confirm-password-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Confirm Password
                </Label>
                <Field
                    name="confirmPassword"
                    id={id}
                    type="password"
                    as={Input}
                />
                <FormError>{errors.confirmPassword}</FormError>
            </FormGroup>
        );
    }

    function renderButtons() {
        return (
            <div className="bottom-buttons">
                <Button color={SUBMIT_BUTTON_COLOR} type="submit">
                    Register
                </Button>
                <Button color={RESET_BUTTON_COLOR} type="reset">
                    Reset
                </Button>
            </div>
        );
    }
};
