import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { LoadingSpinner, PageHeader } from "jack-hermanson-component-lib";
import { Button, Col, Row } from "reactstrap";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { SongRecord } from "@secure-note/shared";
import { useStoreState } from "../../store/_store";
import { SongClient } from "../../clients/SongClient";
import { Link, useHistory } from "react-router-dom";
import { BUTTON_ICON_CLASSES, NEW_BUTTON_COLOR } from "../../constants";
import { FaPlus } from "react-icons/all";
import { SongGlance } from "./SongGlance";
import { useSongs } from "../../hooks/useSongs";

export const SongsIndex: FunctionComponent = () => {
    useAuth();
    usePassword();

    const history = useHistory();

    const { songs } = useSongs();

    return (
        <div>
            <Row>
                <Col>
                    <PageHeader title={"Songs"}>
                        <Button
                            size="sm"
                            color={NEW_BUTTON_COLOR}
                            onClick={() => {
                                history.push("/songs/new");
                            }}
                        >
                            <FaPlus className={BUTTON_ICON_CLASSES} /> New Song
                        </Button>
                    </PageHeader>
                </Col>
            </Row>
            <Row>
                <Col>{renderSongs()}</Col>
            </Row>
        </div>
    );

    function renderSongs() {
        if (songs) {
            return (
                <Fragment>
                    {songs.map(song => (
                        <SongGlance song={song} key={song.id} />
                    ))}
                </Fragment>
            );
        } else {
            return <LoadingSpinner />;
        }
    }
};
