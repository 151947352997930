import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { CONTAINER_FLUID } from "../../constants";
import { Badge, Col, Container, Row } from "reactstrap";

export const Footer: FunctionComponent = () => {
    const now = new Date();

    const [openTime, setOpenTime] = useState(0);
    const [sessionTime, setSessionTime] = useState(0);
    const [startTime] = useState<Date>(new Date());
    const [isPaused, setIsPaused] = useState(false);

    const msPerSecond = 1000;
    const msPerMinute = msPerSecond * 60;

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const difference = now.getTime() - startTime.getTime();
            setSessionTime(difference);
            if (!isPaused) {
                setOpenTime(t => t + msPerSecond);
            }
        }, msPerSecond);

        const onBlur = () => {
            setIsPaused(true);
        };

        const onFocus = () => {
            setIsPaused(false);
        };

        window.addEventListener("blur", onBlur);

        window.addEventListener("focus", onFocus);

        return () => {
            clearInterval(interval);
            window.removeEventListener("blur", onBlur);
            window.removeEventListener("focus", onFocus);
        };
    }, [setOpenTime, isPaused, setIsPaused, setSessionTime, startTime]);

    return (
        <div className="footer-container bg-secondary">
            <Container className="pb-3 pt-2 mt-2" fluid={CONTAINER_FLUID}>
                <Row>
                    <Col className="text-muted d-flex">
                        <p className="mb-0 ms-auto me-auto">
                            <Badge color="primary">{renderOpenTimer()}</Badge>
                            <Badge color="info">{renderSessionTimer()}</Badge>
                            <Badge color="success">{renderCurrentTime()}</Badge>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );

    function renderCurrentTime() {
        return <Fragment>Time: {now.toLocaleTimeString()}</Fragment>;
    }

    function convertTime(ms: number, divisor: number): string {
        return ("0" + Math.floor((ms / divisor) % 60)).slice(-2);
    }

    function renderSessionTimer() {
        return (
            <Fragment>
                Session: {convertTime(sessionTime, msPerMinute)}:
                {convertTime(sessionTime, msPerSecond)}
            </Fragment>
        );
    }

    function renderOpenTimer() {
        return (
            <Fragment>
                Open: {convertTime(openTime, msPerMinute)}:
                {convertTime(openTime, msPerSecond)}
            </Fragment>
        );
    }
};
