import { Fragment, FunctionComponent } from "react";
import { Button, Col, Row } from "reactstrap";
import { LoadingSpinner, PageHeader } from "jack-hermanson-component-lib";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { BUTTON_ICON_CLASSES, NEW_BUTTON_COLOR } from "../../constants";
import { FaPlus } from "react-icons/all";
import { useHistory } from "react-router-dom";
import { useStoreState } from "../../store/_store";
import { TagGlance } from "./TagGlance";

export const TagsIndex: FunctionComponent = () => {
    useAuth();
    usePassword();

    const history = useHistory();

    const tags = useStoreState(state => state.tags);

    return (
        <div>
            {renderPageHeader()}
            {renderTags()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Tags">
                        <Button
                            color={NEW_BUTTON_COLOR}
                            size="sm"
                            onClick={() => {
                                history.push("/tags/new");
                            }}
                        >
                            <FaPlus className={BUTTON_ICON_CLASSES} />
                            New Tag
                        </Button>
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderTags() {
        return (
            <Row>
                <Col>
                    {tags ? (
                        <Fragment>
                            {tags.map(tag => (
                                <TagGlance
                                    tag={tag}
                                    key={tag.id}
                                    className="mb-3 no-mb-last"
                                />
                            ))}
                        </Fragment>
                    ) : (
                        <LoadingSpinner />
                    )}
                </Col>
            </Row>
        );
    }
};
