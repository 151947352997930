import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { usePassword } from "../../utils/usePassword";
import { useStoreActions, useStoreState } from "../../store/_store";
import { TagRecord, TagRequest } from "@secure-note/shared";
import { errorAlert, successAlert } from "jack-hermanson-ts-utils";
import { LoadingSpinner, PageHeader } from "jack-hermanson-component-lib";
import { Col, Row } from "reactstrap";
import { CreateEditTagForm } from "./CreateEditTagForm";
import { TagClient } from "../../clients/TagClient";
import { Encryption } from "../../utils/Encryption";

interface Props extends RouteComponentProps<{ id: string }> {}

export const EditTag: FunctionComponent<Props> = ({ match }: Props) => {
    useAuth();
    usePassword();

    const tags = useStoreState(state => state.tags);
    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);
    const addAlert = useStoreActions(actions => actions.addAlert);
    const fetchTags = useStoreActions(actions => actions.fetchTags);

    const history = useHistory();

    const [tag, setTag] = useState<TagRecord | undefined>(undefined);

    useEffect(() => {
        if (tags) {
            const matchingTag = tags.find(
                t => t.id === parseInt(match.params.id)
            );
            if (matchingTag) {
                setTag(matchingTag);
            } else {
                addAlert(
                    errorAlert(
                        `Tag with ID ${match.params.id} could not be found.`
                    )
                );
            }
        }
    }, [tags, setTag, match.params.id, addAlert]);

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Edit Tag"} />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    {tag ? (
                        <CreateEditTagForm
                            onSubmit={onSubmit}
                            existingTag={tag}
                        />
                    ) : (
                        <LoadingSpinner />
                    )}
                </Col>
            </Row>
        );
    }

    async function onSubmit(tagRequest: TagRequest) {
        const id = parseInt(match.params.id);
        if (password && currentUser?.token) {
            try {
                tagRequest.name = Encryption.encrypt(tagRequest.name, password);
                const updatedTag = await TagClient.edit(
                    id,
                    tagRequest,
                    currentUser.token
                );
                addAlert(
                    successAlert(`tag with ID ${updatedTag.id}`, "updated")
                );
                fetchTags(currentUser.token);
                history.push("/tags");
            } catch (error: any) {
                addAlert(errorAlert(error.message));
            }
        }
    }
};
