import { FunctionComponent, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { PageHeader } from "jack-hermanson-component-lib";
import { RegisterRequest } from "@secure-note/shared";
import { LoginForm } from "./LoginForm";
import { useStoreActions, useStoreState } from "../../store/_store";
import { scrollToTop } from "jack-hermanson-ts-utils";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "../../utils/LocalStorage";

export const LoginPage: FunctionComponent = () => {
    const history = useHistory();
    const currentUser = useStoreState(state => state.currentUser);

    useEffect(() => {
        if (currentUser) {
            const redirectPath = LocalStorage.getRedirectPath();
            if (redirectPath) {
                history.push(redirectPath);
            } else {
                history.push("/auth");
            }
        }
    }, [history, currentUser]);

    const logInWithToken = useStoreActions(
        actions => actions.logInWithPassword
    );

    return (
        <div>
            {renderPageHeader()}
            {renderForm()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Log In" />
                </Col>
            </Row>
        );
    }

    function renderForm() {
        return (
            <Row>
                <Col>
                    <LoginForm onSubmit={onSubmit} />
                </Col>
            </Row>
        );
    }

    async function onSubmit(loginRequest: RegisterRequest) {
        try {
            await logInWithToken(loginRequest);
            const redirectPath = LocalStorage.getRedirectPath();
            if (redirectPath) {
                history.push(redirectPath);
            }
        } catch (error) {
            scrollToTop();
        }
    }
};
