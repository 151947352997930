import { FunctionComponent } from "react";
import { Alert, Badge, Col, Row } from "reactstrap";
import { ActionsDropdown, PageHeader } from "jack-hermanson-component-lib";
import { Link, useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../store/_store";
import { ClickDropdownAction } from "jack-hermanson-ts-utils";
import { AuthTabs } from "./AuthTabs";
import moment from "moment";
import { LONG_DATE_FORMAT } from "../../constants";

export const AuthIndex: FunctionComponent = () => {
    const history = useHistory();

    const currentUser = useStoreState(state => state.currentUser);
    const logOut = useStoreActions(actions => actions.logOut);

    console.log(currentUser);

    return (
        <div>
            <AuthTabs />
            {renderPageHeader()}
            {!currentUser ? renderNotLoggedIn() : renderLoggedIn()}
        </div>
    );

    function renderPageHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title="Authentication">
                        {currentUser && (
                            <ActionsDropdown
                                size="sm"
                                options={[
                                    new ClickDropdownAction(
                                        "Log out",
                                        async () => {
                                            if (currentUser.token) {
                                                await logOut(currentUser.token);
                                            }
                                            history.push("/auth");
                                        }
                                    ),
                                ]}
                            />
                        )}
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderNotLoggedIn() {
        return (
            <Row>
                <Col xs={12} lg={6}>
                    <Alert fade={false} color="info">
                        Have an account? <Link to="/auth/login">Log in</Link>.
                    </Alert>
                </Col>
                <Col xs={12} lg={6}>
                    <Alert fade={false} color="success">
                        Need an account?{" "}
                        <Link to="/auth/register">Register</Link>.
                    </Alert>
                </Col>
            </Row>
        );
    }

    function renderLoggedIn() {
        if (currentUser) {
            return (
                <Row>
                    <Col>
                        <p>
                            You are logged in as{" "}
                            <Badge color="primary">
                                {currentUser.username.capitalizeFirst()}
                            </Badge>
                            .
                        </p>
                        <p>
                            Last login was{" "}
                            <Badge color="primary">
                                {moment(currentUser.lastLogin).format(
                                    LONG_DATE_FORMAT
                                )}
                            </Badge>
                            .
                        </p>
                    </Col>
                </Row>
            );
        }
    }
};
