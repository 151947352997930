import { useStoreState } from "../../store/_store";
import { BookmarkRecord, BookmarkRequest } from "@secure-note/shared";
import * as yup from "yup";
import { Fragment, FunctionComponent } from "react";
import { Field, Form, Formik, FormikErrors, FormikProps } from "formik";
import { FormError, LoadingSpinner } from "jack-hermanson-component-lib";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { RESET_BUTTON_COLOR, SUBMIT_BUTTON_COLOR } from "../../constants";
import { TagsField } from "../Tags/TagsField";
import { SetFieldValue } from "../../utils/SetFieldValue";

interface Props {
    onSubmit: (bookmarkRequest: BookmarkRequest) => Promise<any>;
    existingBookmark?: BookmarkRecord;
}

const validationSchema = yup.object().shape({
    title: yup.string().label("Title").required(),
    comment: yup.string().label("Comment").optional(),
    url: yup.string().url().label("URL").required(),
    tagIds: yup.array().label("Tags"),
});

interface FormValues {
    title: string;
    comment: string;
    url: string;
    tagIds: Array<string>;
}

type Err = FormikErrors<FormValues>;

export const CreateEditBookmarkForm: FunctionComponent<Props> = ({
    onSubmit,
    existingBookmark,
}: Props) => {
    const tags = useStoreState(state => state.tags)?.filter(
        t => t.forBookmarks
    );

    return (
        <Formik
            initialValues={{
                title: existingBookmark?.title || "",
                comment: existingBookmark?.comment || "",
                url: existingBookmark?.url || "",
                tagIds: (existingBookmark?.tagIds?.map(t => t.toString()) ||
                    []) as Array<string>,
            }}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmit({
                    title: data.title,
                    comment: data.comment,
                    url: data.url,
                    tagIds: data.tagIds.map(s => parseInt(s)),
                });
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                errors,
                isSubmitting,
                setFieldValue,
                values,
            }: FormikProps<FormValues>) => (
                <Form>
                    {isSubmitting ? (
                        <LoadingSpinner />
                    ) : (
                        <Fragment>
                            {renderTitle(errors)}
                            {renderUrl(errors)}
                            {renderComment(errors)}
                            {renderTags(values, setFieldValue, errors)}
                            {renderButtons()}
                        </Fragment>
                    )}
                </Form>
            )}
        </Formik>
    );

    function renderTitle(errors: Err) {
        const id = "title-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    Title
                </Label>
                <Field
                    name="title"
                    id={id}
                    type="text"
                    as={Input}
                    autoFocus={true}
                />
                <FormError>{errors.title}</FormError>
            </FormGroup>
        );
    }

    function renderUrl(errors: Err) {
        const id = "url-input";
        return (
            <FormGroup>
                <Label className="form-label required" for={id}>
                    URL
                </Label>
                <Field name="url" id={id} type="url" as={Input} />
                <FormError>{errors.url}</FormError>
            </FormGroup>
        );
    }

    function renderComment(errors: Err) {
        const id = "comment-input";
        return (
            <FormGroup>
                <Label className="form-label" for={id}>
                    Comment
                </Label>
                <Field
                    name="comment"
                    id={id}
                    type="textarea"
                    as={Input}
                    rows={2}
                />
                <FormError>{errors.comment}</FormError>
            </FormGroup>
        );
    }

    function renderTags(
        values: FormValues,
        setFieldValue: SetFieldValue,
        errors: Err
    ) {
        if (tags) {
            return (
                <TagsField
                    tags={tags}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    values={values}
                />
            );
        }
    }

    function renderButtons() {
        return (
            <div className="bottom-buttons">
                <Button type="submit" color={SUBMIT_BUTTON_COLOR}>
                    Save
                </Button>
                <Button type="reset" color={RESET_BUTTON_COLOR}>
                    Reset
                </Button>
            </div>
        );
    }
};
