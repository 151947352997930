import axios from "axios";
import {
    AccountRecord,
    RegisterRequest,
    TokenLoginRequest,
} from "@secure-note/shared";
import { getAuthHeader } from "jack-hermanson-ts-utils";

export abstract class AccountClient {
    private static baseUrl = "/api/accounts";

    static async logInWithPassword(loginRequest: RegisterRequest) {
        const response = await axios.post<AccountRecord>(
            `${this.baseUrl}/login`,
            loginRequest
        );
        return response.data;
    }

    static async logInWithToken(tokenLoginRequest: TokenLoginRequest) {
        const response = await axios.post<AccountRecord>(
            `${this.baseUrl}/token`,
            tokenLoginRequest
        );
        return response.data;
    }

    static async register(registerRequest: RegisterRequest) {
        const response = await axios.post<AccountRecord>(
            this.baseUrl,
            registerRequest
        );
        return response.data;
    }

    static async logOut(token: string) {
        const response = await axios.post<boolean>(
            `${this.baseUrl}/logout`,
            null,
            getAuthHeader(token)
        );
        return response.data;
    }
}
