import { Dispatch, Fragment, FunctionComponent, SetStateAction } from "react";
import { FaEye, FaEyeSlash } from "react-icons/all";

type InputType = "password" | "text";

interface Props {
    inputType: InputType;
    setInputType: Dispatch<SetStateAction<InputType>>;
    elementId?: string;
}

export const EyeToggle: FunctionComponent<Props> = ({
    inputType,
    setInputType,
    elementId,
}: Props) => {
    return (
        <Fragment>
            {inputType === "text" ? (
                <FaEye
                    className="eye-toggle"
                    onClick={() => {
                        setInputType("password");
                        focusOnElement();
                    }}
                />
            ) : (
                <FaEyeSlash
                    className="eye-toggle"
                    onClick={() => {
                        setInputType("text");
                        focusOnElement();
                    }}
                />
            )}
        </Fragment>
    );

    function focusOnElement() {
        if (elementId) {
            const element = document.getElementById(
                elementId
            ) as HTMLInputElement;
            if (element) {
                const end = element.value.length;
                setTimeout(() => {
                    element.setSelectionRange(end, end);
                }, 1);
                element.focus();
            }
        }
    }
};
