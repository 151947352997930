import { SongRecord } from "@secure-note/shared";
import { useEffect, useState } from "react";
import { useStoreState } from "../store/_store";
import { SongClient } from "../clients/SongClient";

export const useSongs = (): {
    songs: SongRecord[] | undefined;
} => {
    const [songs, setSongs] = useState<SongRecord[] | undefined>();

    const currentUser = useStoreState(state => state.currentUser);

    useEffect(() => {
        if (currentUser?.token) {
            SongClient.getAll(currentUser.token).then(data => {
                setSongs(data);
            });
        }
    }, [setSongs, currentUser]);

    return { songs };
};
