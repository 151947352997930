import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
    ActionsDropdown,
    KeyValCardBody,
    LoadingSpinner,
    PageHeader,
} from "jack-hermanson-component-lib";
import { useAuth } from "../../utils/useAuth";
import { BookmarkRecord } from "@secure-note/shared";
import { useStoreState } from "../../store/_store";
import { BookmarkClient } from "../../clients/BookmarkClient";
import { usePassword } from "../../utils/usePassword";
import { Encryption } from "../../utils/Encryption";
import { BookmarkTag } from "./BookmarkTag";
import moment from "moment";
import { LONG_DATE_FORMAT } from "../../constants";
import {
    ClickDropdownAction,
    LinkDropdownAction,
} from "jack-hermanson-ts-utils";

interface Props extends RouteComponentProps<{ id: string }> {}

export const EmbedMedia: FunctionComponent<Props> = ({ match }) => {
    useAuth();
    usePassword();

    const currentUser = useStoreState(state => state.currentUser);
    const password = useStoreState(state => state.password);
    const tags = useStoreState(state => state.tags);

    const [bookmark, setBookmark] = useState<BookmarkRecord | undefined>(
        undefined
    );

    useEffect(() => {
        if (currentUser?.token && password) {
            BookmarkClient.getOne(
                parseInt(match.params.id),
                currentUser.token
            ).then(data => {
                setBookmark(Encryption.decryptBookmark(data, password));
            });
        }
    }, [setBookmark, currentUser, match.params.id, password]);

    return (
        <div>
            {renderHeader()}
            {renderMedia()}
            {renderInfo()}
        </div>
    );

    function renderHeader() {
        return (
            <Row>
                <Col>
                    <PageHeader title={"Show Embedded Media"}>
                        {bookmark && (
                            <ActionsDropdown
                                options={[
                                    new ClickDropdownAction(
                                        "Copy URL",
                                        async () => {
                                            await navigator.clipboard.writeText(
                                                bookmark.url
                                            );
                                        }
                                    ),
                                    undefined,
                                    new LinkDropdownAction(
                                        "Edit",
                                        `/bookmarks/edit/${bookmark.id}`
                                    ),
                                ]}
                                size="sm"
                            />
                        )}
                    </PageHeader>
                </Col>
            </Row>
        );
    }

    function renderMedia() {
        return (
            <Row>
                <Col>
                    {bookmark ? (
                        <div>
                            <video width="100%" height="auto" controls>
                                <source src={bookmark.url} type="video/mp4" />
                            </video>
                        </div>
                    ) : (
                        <LoadingSpinner />
                    )}
                </Col>
            </Row>
        );
    }

    function renderInfo() {
        return (
            <Row>
                <Col>
                    {bookmark && tags ? (
                        <KeyValCardBody
                            keyValPairs={[
                                {
                                    key: "Comment",
                                    val: bookmark.comment || (
                                        <span className="text-muted">N/A</span>
                                    ),
                                },
                                {
                                    key: "Url",
                                    val: (
                                        <a href={bookmark.url} className="ps-0">
                                            {bookmark.url}
                                        </a>
                                    ),
                                },
                                {
                                    key: "Tags",
                                    val: (
                                        <BookmarkTag
                                            className="ps-0"
                                            bookmark={bookmark}
                                            tags={tags}
                                        />
                                    ),
                                },
                                {
                                    key: "Created",
                                    val: moment(bookmark.created).format(
                                        LONG_DATE_FORMAT
                                    ),
                                },
                                {
                                    key: "Updated",
                                    val: moment(bookmark.updated).format(
                                        LONG_DATE_FORMAT
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <LoadingSpinner />
                    )}
                </Col>
            </Row>
        );
    }
};
