import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { TagBadge } from "../Tags/TagBadge";
import { BookmarkRecord, TagRecord } from "@secure-note/shared";

interface Props {
    bookmark: BookmarkRecord;
    selectedTagIds?: number[] | undefined;
    setSelectedTagIds?: Dispatch<SetStateAction<number[]>> | undefined;
    tags: TagRecord[];
    className?: string;
}

export const BookmarkTag: FunctionComponent<Props> = ({
    bookmark,
    selectedTagIds,
    setSelectedTagIds,
    tags,
    className,
}: Props) => {
    return (
        <div className={`d-block ${className || ""}`}>
            {bookmark.tagIds.map(tagId => (
                <TagBadge
                    key={tagId}
                    tagId={tagId}
                    selectedTagIds={selectedTagIds}
                    setSelectedTagIds={setSelectedTagIds}
                    tags={tags}
                />
            ))}
        </div>
    );
};
