import { Fragment, FunctionComponent, useState } from "react";
import { TagRecord } from "@secure-note/shared";
import moment from "moment";
import { Card, CardBody } from "reactstrap";
import {
    ActionCardHeader,
    ActionsDropdown,
    ConfirmationModal,
} from "jack-hermanson-component-lib";
import {
    ClickDropdownAction,
    LinkDropdownAction,
    successAlert,
} from "jack-hermanson-ts-utils";
import { DATE_FORMAT } from "../../constants";
import { useStoreActions, useStoreState } from "../../store/_store";
import { TagClient } from "../../clients/TagClient";

interface Props {
    tag: TagRecord;
    className?: string;
}

export const TagGlance: FunctionComponent<Props> = ({
    tag,
    className,
}: Props) => {
    const created = moment(tag.created);
    const updated = moment(tag.updated);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const currentUser = useStoreState(state => state.currentUser);

    const addAlert = useStoreActions(actions => actions.addAlert);
    const fetchTags = useStoreActions(actions => actions.fetchTags);

    return (
        <Fragment>
            {renderCard()}
            {renderConfirmModal()}
        </Fragment>
    );

    function renderCard() {
        return (
            <Card className={className || ""}>
                <ActionCardHeader title={tag.name}>
                    <ActionsDropdown
                        options={[
                            new LinkDropdownAction(
                                "Edit",
                                `/tags/edit/${tag.id}`
                            ),
                            new ClickDropdownAction("Delete", () => {
                                setShowDeleteModal(true);
                            }),
                        ]}
                        size="sm"
                    />
                </ActionCardHeader>
                <CardBody className="pt-1">
                    <div>
                        <small className="text-muted d-block my-2">
                            Created {created.format(DATE_FORMAT)}
                            {!created.isSame(updated) && (
                                <Fragment>
                                    {" "}
                                    (Updated {updated.format(DATE_FORMAT)})
                                </Fragment>
                            )}
                        </small>
                    </div>
                    <div>
                        <dl className="mb-0">
                            <dt>Allowed on Bookmarks</dt>
                            <dd>{tag.forBookmarks.toString()}</dd>

                            <dt>Allowed on Notes</dt>
                            <dd className="mb-0">{tag.forNotes.toString()}</dd>
                        </dl>
                    </div>
                </CardBody>
            </Card>
        );
    }

    function renderConfirmModal() {
        return (
            <ConfirmationModal
                isOpen={showDeleteModal}
                setIsOpen={setShowDeleteModal}
                title={"Confirm Tag Deletion"}
                onConfirm={deleteTag}
                buttonColor="danger"
                buttonText="Delete"
            >
                Are you sure you want to delete this tag?
            </ConfirmationModal>
        );
    }

    async function deleteTag() {
        if (currentUser?.token) {
            const deleted = await TagClient.delete(tag.id, currentUser.token);
            if (deleted) {
                addAlert(successAlert(`tag with ID ${tag.id}`, "deleted"));
                await fetchTags(currentUser.token);
            }
        }
    }
};
