import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Badge } from "reactstrap";
import { TagRecord } from "@secure-note/shared";

interface Props {
    tagId: number;
    selectedTagIds?: number[] | undefined;
    setSelectedTagIds?: Dispatch<SetStateAction<number[]>> | undefined;
    tags: TagRecord[];
}

export const TagBadge: FunctionComponent<Props> = ({
    tagId,
    selectedTagIds,
    setSelectedTagIds,
    tags,
}: Props) => {
    return (
        <Badge
            className="me-1 hover-mouse"
            key={tagId}
            onClick={() => clickTag(tagId)}
            color={selectedTagIds?.includes(tagId) ? "danger" : "secondary"}
        >
            {tags.find(t => t.id === tagId)?.name || "N/A"}
        </Badge>
    );

    function clickTag(tagId: number) {
        setSelectedTagIds?.(prev => {
            if (prev.includes(tagId)) {
                return prev.filter(t => t !== tagId);
            }
            return [...prev, tagId];
        });
    }
};
