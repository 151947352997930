import { FunctionComponent } from "react";
import { Col, Row } from "reactstrap";
import { PageHeader } from "jack-hermanson-component-lib";

interface Props {
    title: string;
    message: string;
}

export const ErrorPage: FunctionComponent<Props> = ({
    title,
    message,
}: Props) => {
    return (
        <div>
            <Row>
                <Col>
                    <PageHeader title={title} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="lead">{message}</p>
                </Col>
            </Row>
        </div>
    );
};
